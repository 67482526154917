// components
import { useState, useEffect } from 'react'
import { CustDatePicker } from '../common/commonElements'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Dayjs } from 'dayjs'
import axios from 'axios'

// tools
import NumberOnly from '../../tools/numberOnly'
import User from '../../tools/userInfo'
import Swal from 'sweetalert2'

interface Playdate {
  drawnum: number
  playdate: string
}

interface DateData {
  [key: string]: Playdate[]
  power: Playdate[]
  mega: Playdate[]
}

interface Result {
  win: boolean
  rank: number
  white: string[]
  special: string
}

const CheckNumber = () => {
  const { api } = User()
  const [game, setGame] = useState<string>('power')
  const [date, setDate] = useState<string>('')
  const [numbers, setNumbers] = useState<string[]>(['', '', '', '', '', ''])
  const [dates, setDates] = useState<DateData>({
    power: [],
    mega: [],
  })
  const [result, setResult] = useState<Result>({
    win: false,
    rank: 0,
    white: [],
    special: '',
  })
  const [show, setShow] = useState(false)

  const handleChange = (event: SelectChangeEvent) => {
    setGame(event.target.value)
  }

  const handleDate = (event: Dayjs | null) => {
    if (event) {
      setDate(event.format('YYYY-MM-DD'))
    }
  }

  const handleData = (value: string, index: number) => {
    const result = NumberOnly(value)
    const copy = [...numbers]
    copy[index] = result
    setNumbers(copy)
  }

  useEffect(() => {
    axios
      .get(`${api}/playdate/mm`, {
        headers: {
          'ngrok-skip-browser-warning': '69420',
        },
      })
      .then(({ data }) => {
        setDates(prev => ({ ...prev, mega: data.data }))
      })

    axios
      .get(`${api}/playdate/pb`, {
        headers: {
          'ngrok-skip-browser-warning': '69420',
        },
      })
      .then(({ data }) => {
        setDates(prev => ({ ...prev, power: data.data }))
      })
  }, [])

  const checkAxios = (type: string, date: string) => {
    if (type && date && !numbers.includes('')) {
      const allData = dates[type]
      const draw = allData.find(item => {
        if (item.playdate === date) {
          return item
        }
      }) as Playdate

      const newType = type === 'power' ? 'pb' : 'mm'

      axios
        .get(`${api}/prize/${newType}/${draw.drawnum}`, {
          headers: {
            'ngrok-skip-browser-warning': '69420',
          },
        })
        .then(({ data }) => {
          const Win = [...data.data.winningNumber.split(',')]

          setResult(prev => ({
            ...prev,
            white: [Win[0], Win[1], Win[2], Win[3], Win[4]],
            special: Win[5],
          }))
        })

      axios
        .post(
          `${api}/result/${newType}/${draw.drawnum}`,
          { nums: numbers },
          {
            headers: {
              'ngrok-skip-browser-warning': '69420',
            },
          }
        )
        .then(({ data }) => {
          setResult(prev => ({
            ...prev,
            rank: data.data.rank,
            win: data.data.isWinner,
          }))
          setShow(true)
        })
    } else {
      Swal.fire({
        title: 'Please put all the information',
        icon: 'error',
        allowOutsideClick: false,
      })
    }
  }

  return (
    <section className="sec-chk-num">
      <div className="inner">
        <div className="chk-num box style01">
          <div className="tit-area">
            <h3 className="f16">Check Your Numbers</h3>
          </div>
          <div className="cont-area">
            <div className="balls-wrap">
              <div className="balls">
                {numbers.map((num, index) => (
                  <div
                    className={
                      index === 5
                        ? game
                          ? game === 'mega'
                            ? 'ball mega'
                            : 'ball power'
                          : 'ball'
                        : `ball`
                    }
                    key={index}
                  >
                    <input
                      type="tel"
                      value={num}
                      onChange={e => handleData(e.target.value, index)}
                      maxLength={2}
                      readOnly={show}
                    />
                  </div>
                ))}

                <div
                  className="btn style03 refresh"
                  onClick={() => {
                    setNumbers(['', '', '', '', '', ''])
                    setShow(false)
                  }}
                >
                  <span className="material-symbols-outlined">restart_alt</span>
                </div>
              </div>
            </div>
            <div className="filter-area">
              <FormControl>
                <InputLabel id="demo-simple-select-label">Game</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id=""
                  value={game}
                  label="Game"
                  onChange={handleChange}
                  readOnly={show}
                >
                  <MenuItem value="power">Powerball</MenuItem>
                  <MenuItem value="mega">Mega Millions</MenuItem>
                </Select>
              </FormControl>

              <CustDatePicker
                onChange={handleDate}
                target={dates[game].map(item => item.playdate)}
                readOnly={show}
              />
            </div>
            <div
              className="btn style01"
              onClick={() => !show && checkAxios(game, date)}
            >
              CHECK YOUR NUMBERS
            </div>
          </div>

          {show && (
            <div className="result-area">
              <div className="balls md">
                {result.white.map((num, index) => (
                  <div
                    key={`${index}_${num}`}
                    className={
                      numbers.findIndex(target => target === num) > -1
                        ? 'ball matching'
                        : 'ball'
                    }
                  >
                    {num}
                  </div>
                ))}
                <div
                  className={
                    numbers[5] === result.special
                      ? `ball matching ${game}`
                      : `ball ${game}`
                  }
                >
                  {result.special}
                </div>
              </div>
              <p className="f18">
                {result.win ? `Division ${result.rank}` : 'No Win'}
                <span>
                  (
                  <span>
                    {result.white.filter(num =>
                      numbers.slice(0, 5).includes(num)
                    ).length + (result.special == numbers[5] ? 1 : 0)}
                  </span>
                  Matching )
                </span>
              </p>
              <div className="btn close" onClick={() => setShow(false)}>
                <span className="material-symbols-outlined">close</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default CheckNumber

import { Link } from 'react-router-dom'

// components
import Wrapper from '../components/common/wrapper'

// img
import NewsImg from '../img/sub/newsimg.png'

const News = () => {
  return (
    <Wrapper>
      <div className="sub news">
        <div className="top-info-area inner">
          <div className="txt-area">
            <p className="f40">News</p>
            <p className="f18 txt">News</p>
          </div>
        </div>
        <div className="cont inner">
          <div className="news-lists ">
            <Link to="" className="item">
              <img src={NewsImg} alt="" />

              <p className="f18">
                Powerball player wins $2 billion after excruciating delay
              </p>
              <p className="f14">Publisher: news.com.au</p>
              <p className="f14">Published date: 8 April, 2024</p>
            </Link>
            <Link to="" className="item">
              <img src={NewsImg} alt="" />
              <p className="f18">
                Powerball player wins $2 billion after excruciating delay
              </p>
              <p className="f14">Publisher: news.com.au</p>
              <p className="f14">Published date: 8 April, 2024</p>
            </Link>
            <Link to="" className="item">
              <img src={NewsImg} alt="" />

              <p className="f18">
                Powerball player wins $2 billion after excruciating delay
              </p>
              <p className="f14">Publisher: news.com.au</p>
              <p className="f14">Published date: 8 April, 2024</p>
            </Link>
            <Link to="" className="item">
              <img src={NewsImg} alt="" />

              <p className="f18">
                Powerball player wins $2 billion after excruciating delay
              </p>
              <p className="f14">Publisher: news.com.au</p>
              <p className="f14">Published date: 8 April, 2024</p>
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default News

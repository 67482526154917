import Skeleton from '@mui/material/Skeleton'

// components
import BallSection from './ballSection'
import CountDown from '../common/countdown'

// img
import MegaLogo from './../../img/common/megamillions-logo.png'
import PowerLogo from './../../img/common/powerball-logo.png'

// type
import type { LottoProps } from '../../pages/main'

export interface GameProps {
  loading: boolean
  lottery: LottoProps[]
}

const Game = ({ loading, lottery }: GameProps) => {
  return (
    <section className="sec-game">
      <div className="inner">
        {lottery.map(item => {
          const type =
            item.type === 'MM' ? 'mega' : item.type === 'PB' ? 'power' : ''

          return (
            <div className="box style01" key={item.type}>
              <div className="cont-area">
                <img
                  src={type === 'mega' ? MegaLogo : PowerLogo}
                  alt={type === 'mega' ? 'Mega Millions' : 'Power Ball'}
                  className="game-logo"
                />

                <BallSection
                  type={type}
                  original={item.type}
                  loading={loading}
                />

                <div className="next">
                  <p className="f24">NEXT ESTIMATED JACKPOT</p>
                  <p className="f16 date">
                    {loading ? (
                      <Skeleton variant="rounded" animation="wave" />
                    ) : (
                      `${new Date(
                        item.auDrawTime.split(' ')[0]
                      ).toDateString()} (Draw #${item.drawnum})`
                    )}
                  </p>

                  <p className="f40">
                    {loading ? (
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        height={60}
                      />
                    ) : item.isDrawdate ? (
                      'To Be Determined'
                    ) : (
                      `$ ${(
                        item.jackpot * Number(item.exchange)
                      ).toLocaleString('au')}`
                    )}
                  </p>
                </div>
              </div>
              <div className="bottom-area">
                <div className="btn-wrap">
                  <div
                    className="btn style02"
                    onClick={() =>
                      (location.href = `/${
                        item.type === 'MM' ? 'mega' : 'power'
                      }?tab=how`)
                    }
                  >
                    How to Play
                  </div>

                  <div
                    className="btn style02"
                    onClick={() => (location.href = `/result?tab=${type}`)}
                  >
                    Past Result
                  </div>

                  <div
                    className="btn style02"
                    onClick={() =>
                      (location.href = `/stat?type=${type}&tab=frequency`)
                    }
                  >
                    Statistic
                  </div>
                </div>

                <CountDown date={item.auOrderTime} />

                <div
                  className="btn style01 sm"
                  onClick={() => (location.href = `/${type}`)}
                >
                  PLAY NOW
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Game

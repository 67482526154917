// img
import GooglePlay from '../../img/common/google-play-logo.svg'
import AppStore from '../../img/common/appstore-logo.svg'

// constants
import { Menu } from '../../constants/footerMenu'
import FloatingNav from '../../constants/floatingNav'

const Footer = () => {
  return (
    <footer className="footer">
      <FloatingNav />
      <div className="inner">
        <ul className="fnb">
          {Menu.map(item => (
            <li key={item.id} className="f16">
              {item.text}
              <ul className="sub-items">
                {item.subItems.map(subItems => (
                  <li
                    key={subItems.id}
                    onClick={() => (location.href = subItems.link)}
                  >
                    {subItems.text}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>

        <div className="app-download">
          <div className="box style01">
            <p className="f16">Must be 18 or older to play.</p>
          </div>
          <p className="f14 copyright">
            © 2024 LOTTERY CLUSTER Inc. All Rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

// mui - datepicker
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dayjs } from 'dayjs'
// mui - dialog
import Dialog from '@mui/material/Dialog'

interface InputProps {
  label: string
}

interface SelectProps {
  label: string
  options: string[]
}

interface ModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  children: ReactNode
}

interface CustModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  onClose: (value: string) => void
  children: ReactNode
}

interface EmailInfo {
  name: string
  email: string
  topic: string
  content: string
}

interface TextAreaProps {
  value: string
  key: string
  setValue: Dispatch<SetStateAction<EmailInfo>>
}

const Input = ({ label }: InputProps) => {
  const [inputValue, setInputValue] = useState('')

  return (
    <div className="cust-input">
      <input
        type="text"
        placeholder=""
        value={inputValue}
        onChange={e => {
          setInputValue(e.target.value)
        }}
      />
      {label && (
        <label className={inputValue !== '' ? 'upper' : ''}>{label}</label>
      )}
      <div className="btn reset">
        <span
          className="material-symbols-outlined"
          onClick={() => {
            setInputValue('')
          }}
        >
          cancel
        </span>
      </div>
    </div>
  )
}

const TextArea = ({ value, key, setValue }: TextAreaProps) => {
  return (
    <div className="cust-textarea">
      <textarea
        name=""
        id=""
        value={value}
        onChange={e => setValue(prev => ({ ...prev, [key]: e.target.value }))}
      />
    </div>
  )
}

const Check = () => {
  return <div></div>
}

const Select = ({ options, label }: SelectProps) => {
  const [selectOpen, setSelectOpen] = useState(false)
  const [selected, setSelected] = useState('')
  const selectRef = useRef<HTMLDivElement>(null)

  const selectHandle = (option: string) => {
    setSelected(option)
    setSelectOpen(false)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setSelectOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="cust-select" ref={selectRef}>
      <div
        className={`selected ${selectOpen ? 'open' : ''}`}
        onClick={() => {
          setSelectOpen(current => !current)
        }}
      >
        <p className="value f16">
          {selected}
          <label className={selected !== '' || selectOpen ? 'upper' : ''}>
            {label}
          </label>
        </p>
        <div className="btn arr">
          <span className="material-symbols-outlined">expand_more</span>
        </div>
      </div>
      {selectOpen ? (
        <ul className="option chk">
          {options.map((option, index) => (
            <li
              key={index}
              className={`item ${option === selected ? 'chk' : ''}`}
              onClick={() => selectHandle(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}

const CustDatePicker = ({
  onChange,
  target,
  readOnly,
}: {
  onChange: (event: Dayjs | null) => void
  target: string[]
  readOnly: boolean
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        readOnly={readOnly}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': { top: '-8px' },
        }}
        onChange={onChange}
        shouldDisableDate={date => {
          const converted = date.format('YYYY-MM-DD')
          const allow = !target.includes(converted)

          return allow
        }}
      />
    </LocalizationProvider>
  )
}

const CustModal = ({ open, onClose, children }: CustModalProps) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { maxWidth: '1200px', maxHeight: '90vh' } }}
    >
      <div className="cust-modal">{children}</div>
    </Dialog>
  )
}

const Modal = ({ open, setOpen, children }: ModalProps) => {
  return (
    <>
      {open ? (
        <div
          className="modal back"
          onClick={() => {
            setOpen(false)
          }}
        >
          <div className="cont">
            <div className="tit-area">
              <span
                className="material-symbols-outlined btn close"
                onClick={() => {
                  setOpen(false)
                }}
              >
                close
              </span>
            </div>
            <div className="cont-area">{children}</div>
            <div className="bottom-area"></div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export { Input, TextArea, Check, Select, CustDatePicker, Modal, CustModal }

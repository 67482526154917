import { Dispatch, SetStateAction, useState } from 'react'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import Swal from 'sweetalert2'

// tools
import User from '../../tools/userInfo'
import NumberOnly from '../../tools/numberOnly'

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>
}

const Limit = ({ setOpen }: ModalContent) => {
  const { info, api, token } = User()
  const [limit, setLimit] = useState('')

  const limitSet = (input: string) => {
    const number = Number(NumberOnly(input))

    if (number <= 10000) {
      setLimit(NumberOnly(input))
    } else {
      Swal.fire({
        title: 'Error',
        text: 'You cannot set weekly spend limit more than $10,000.',
        icon: 'error',
        allowOutsideClick: false,
      })
    }
  }

  const limitAxios = () => {
    axios
      .post(
        `${api}/auth/spend/limit`,
        {
          amount: Number(limit),
        },
        {
          headers: {
            'ngrok-skip-browser-warning': '69420',
            Authorization: 'bearer ' + token,
          },
        }
      )
      .then(({ data }) => {
        Swal.fire({
          title: 'Success',
          text: `Your Weekly Spending Limit has been set to $${limit}.`,
          icon: 'success',
          allowOutsideClick: false,
        })
      })
      .catch(({ response }) => {
        Swal.fire({
          title: 'Error',
          text: response.data.message,
          icon: 'error',
          allowOutsideClick: false,
        })
      })
  }

  return (
    <div className="spending-limit">
      <div className="tit-area flex">
        <h3 className="f24">Spending Limit</h3>
        <span
          className="btn close material-symbols-outlined"
          onClick={() => setOpen(false)}
        >
          close
        </span>
      </div>
      <div className="cont">
        <p className="f18">
          Your current weekly spend limit is{' '}
          <span>${info.weeklySpendLimit.toLocaleString('au')}</span>
        </p>
        <table className="form">
          <tr>
            <th>Amount</th>
            <td>
              <TextField
                variant="outlined"
                placeholder="10,000"
                value={limit}
                onChange={({ target }) => limitSet(target.value)}
              />
            </td>
          </tr>
        </table>
        <p className="exp">
          The maximum weekly spending limit is $10,000. You can decrease your
          spending limit at any time. As per our Responsible Gambling Policy,
          spending limits cannot be increased more than once in a 24 hour
          period. Once you reach your limit, our system will alert you and stop
          you from making a deposit or purchase.
        </p>
      </div>
      <div className="bottom-area flex">
        <div className="btn-wrap">
          <div className="btn style01 outline" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div className="btn style01 em" onClick={() => limitAxios()}>
            Set
          </div>
        </div>
      </div>
    </div>
  )
}

export default Limit

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link } from 'react-router-dom'

// components
import Prize from './prize'
import NewsTicker from './newsTicker'

// constants
import { EventSliderSettings } from '../../constants/sliderSettings'

// img
import mainSliderImg01 from '../../img/main/main-banner01.png'
import mainSliderImg02 from '../../img/main/main-banner02.png'

// type
import type { GameProps } from './game'

const EventSlider = ({ loading, lottery }: GameProps) => {
  const simpleDateConverter = (original: string) => {
    const [y, m, d] = original.split('-')

    const monthConverter = (month: number) => {
      switch (month) {
        case 1:
          return 'January'
          break

        case 2:
          return 'February'
          break

        case 3:
          return 'March'
          break

        case 4:
          return 'April'
          break

        case 5:
          return 'May'
          break

        case 6:
          return 'June'
          break

        case 7:
          return 'July'
          break

        case 8:
          return 'August'
          break

        case 9:
          return 'September'
          break

        case 10:
          return 'October'
          break

        case 11:
          return 'November'
          break

        case 12:
          return 'December'
          break
      }
    }

    return `${monthConverter(Number(m))} ${d}, ${y}`
  }

  const amountMaker = (amount: number) => {
    const inmil = Number((amount / 1000000).toFixed(1))
    let final = ''

    if (inmil > 1000) {
      const inbil = inmil / 1000
      final = `$ ${Math.floor(inbil * 100) / 100} Billions`
    } else if (inmil > 0) {
      final = `$ ${String(inmil)} Millions`
    }

    return final
  }

  return (
    <section className="sec-slider">
      <div className="inner">
        <div className="prize-news-area flex">
          {/* <Prize />
          <NewsTicker /> */}
        </div>

        <Slider {...EventSliderSettings}>
          {lottery.map(item => (
            <div className="slider" key={item.type}>
              <div className="item">
                <div className="txt-wrap">
                  {item.type === 'MM' ? (
                    <p className="f18 mega">Mega Millions</p>
                  ) : (
                    <p className="f18 power">Powerball</p>
                  )}

                  <p className="f60">
                    {item.isDrawdate
                      ? 'To Be Determined'
                      : amountMaker(item.jackpot * Number(item.exchange))}
                  </p>
                  <p className="f24">
                    {simpleDateConverter(item.auDrawTime.split(' ')[0])}
                  </p>
                  <Link
                    to={item.type === 'MM' ? '/mega' : '/power'}
                    className="btn style01"
                  >
                    PLAY NOW
                  </Link>
                </div>
                <img
                  src={item.type === 'PB' ? mainSliderImg01 : mainSliderImg02}
                  alt="Cluster Event"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default EventSlider

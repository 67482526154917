import { useEffect, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

// constants
import StatMenu from '../constants/statMenu'

// components
import Wrapper from '../components/common/wrapper'
import Bar from '../components/statistics/bar'
import Oddeven from '../components/statistics/oddeven'
import HighLow from '../components/statistics/highlow'
import Consecutive from '../components/statistics/consecutive'
import Pattern from '../components/statistics/pattern'
import ErrorPage from './errorPage'

export interface Pie {
  type: string
  month: string
}

const Stat = () => {
  const [menu, setMenu] = useState('')
  const [tab, setTab] = useState('')
  const [month, setMonth] = useState('1')

  useEffect(() => {
    const search = location.search.slice(1, location.search.length)

    if (search) {
      const split = search.split('&')
      const type = split[0].split('=')[1]
      const tab = split[1].split('=')[1]

      setMenu(type)
      setTab(tab)
    } else {
      setMenu('mega')
      setTab('frequency')
    }
  }, [])

  const viewChange = (about: string) => {
    switch (about) {
      case 'frequency':
        return <Bar type={menu} menu={about} />
        break

      case 'lastdrawn':
        return <Bar type={menu} menu={about} />
        break

      case 'oddeven':
        return <Oddeven type={menu} month={month} />
        break

      case 'highlow':
        return <HighLow type={menu} month={month} />
        break

      case 'consecutive':
        return <Consecutive type={menu} month={month} />
        break

      case 'pattern':
        return <Pattern type={menu} month={month} />
        break

      default:
        return <ErrorPage />
    }
  }

  return (
    <Wrapper>
      <div className="sub stat">
        <div className="top-info-area inner">
          <div className="txt-area">
            <p className="f40">Statistics</p>
            <p className="f18 txt">
              Analyze the numbers with the highest probability!
            </p>
          </div>
          {/* <img src={StatTopImg} alt="Statistics" /> */}
        </div>

        <div className="cont inner">
          <div className="tab-area">
            <ul className="style01">
              <li
                className={menu === 'mega' ? 'current' : ''}
                onClick={() => (location.href = `/stat?type=mega&tab=${tab}`)}
              >
                Mega Millions
              </li>

              <li
                className={menu === 'power' ? 'current' : ''}
                onClick={() => (location.href = `/stat?type=power&tab=${tab}`)}
              >
                Powerball
              </li>
            </ul>

            {tab !== 'frequency' && tab !== 'lastdrawn' && (
              <Select
                id=""
                value={month}
                onChange={({ target }) => setMonth(target.value)}
                className="month"
              >
                <MenuItem value={'1'}>1 Month</MenuItem>
                <MenuItem value={'2'}>2 Months</MenuItem>
                <MenuItem value={'3'}>3 Months</MenuItem>
                <MenuItem value={'4'}>4 Months</MenuItem>
                <MenuItem value={'5'}>5 Months</MenuItem>
                <MenuItem value={'12'}>1 Year</MenuItem>
                <MenuItem value={'36'}>3 Years</MenuItem>
                <MenuItem value={'60'}>5 Years</MenuItem>
              </Select>
            )}
          </div>

          <div className="tab-area">
            <ul className="style02">
              {StatMenu.map(item => (
                <li
                  key={item.text}
                  className={tab === item.menu ? 'current' : ''}
                  onClick={() =>
                    (location.href = `/stat?type=${menu}&tab=${item.menu}`)
                  }
                >
                  {item.text}
                </li>
              ))}
            </ul>
          </div>

          {viewChange(tab)}
        </div>
      </div>
    </Wrapper>
  )
}

export default Stat

import { useState } from "react";

// components
import Wrapper from "../components/common/wrapper";
import Quick from "../components/game/quick";
import Choose from "../components/game/choose";
import Win from "../components/game/win";
import About from "../components/game/about";

interface HowProps {
  type: string;
}

const How = ({ type }: HowProps) => {
  const [tab, setTab] = useState("quick");

  const Menu = [
    {
      text: "Quick Pick",
      loc: "quick",
    },
    {
      text: "Choose Number",
      loc: "choose",
    },
    {
      text: "Ways to Win",
      loc: "win",
    },
    {
      text: `About ${type === "power" ? "PowerBall" : "Mega Millions"}`,
      loc: "about",
    },
  ];

  const handleView = (tab: string) => {
    switch (tab) {
      case "quick":
        return <Quick type={type} />;
        break;

      case "choose":
        return <Choose type={type} />;
        break;

      case "win":
        return <Win type={type} />;
        break;

      case "about":
        return <About type={type} />;
        break;
    }
  };

  const tabChange = (loc: string) => {
    setTab(loc);
  };

  return (
    <Wrapper>
      <div className="sub how">
        <div className="tab-area">
          <ul className="style02">
            {Menu.map((menu) => (
              <li
                key={menu.text}
                className={menu.loc === tab ? "current" : ""}
                onClick={() => tabChange(menu.loc)}
              >
                {menu.text}
              </li>
            ))}
          </ul>
        </div>

        {handleView(tab)}
      </div>
    </Wrapper>
  );
};

export default How;

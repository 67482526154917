import { useState } from "react";

// components
import { CustModal } from "../../components/common/commonElements";
import Limit from "./limit";
import Notification from "./notification";
import PersonalInfo from "./personalInfo";
import ChangePassword from "./changePassword";
import PayMethod from "./payMethod";
import Delete from "./delete";

// tools
import User from "../../tools/userInfo";

const Settings = () => {
  const { info } = User();
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState("");

  const handleClickOpen = (type: string) => {
    setContent(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeView = (type: string) => {
    switch (type) {
      case "personal":
        return <PersonalInfo setOpen={setOpen} />;
        break;

      case "password":
        return <ChangePassword setOpen={setOpen} />;
        break;

      case "paymethod":
        return <PayMethod setOpen={setOpen} />;
        break;

      case "limit":
        return <Limit setOpen={setOpen} />;
        break;

      case "notification":
        return <Notification setOpen={setOpen} />;
        break;
    }
  };

  return (
    <>
      <div className="settings">
        <div className="item">
          <p className="f24">Personal Information</p>
          <p className="f16">
            View or edit your personal information. This will be used to contact
            you in the event of a win.
          </p>
          <div
            className="btn style01 sm edit"
            onClick={() => handleClickOpen("personal")}
          >
            Edit
          </div>
        </div>

        {info.provider === "cluster" && (
          <div className="item">
            <p className="f24">Change Password</p>
            <p className="f16">Change your Lottery Cluster account password.</p>
            <div
              className="btn style01 sm edit"
              onClick={() => handleClickOpen("password")}
            >
              Change
            </div>
          </div>
        )}

        <div className="item">
          <p className="f24">Payment Methods</p>
          <p className="f16">Manage Payment and Funds Methods</p>
          <div
            className="btn style01 sm edit"
            onClick={() => {
              handleClickOpen("paymethod");
            }}
          >
            Edit
          </div>
        </div>

        <div className="item">
          <p className="f24">Weekly Spend Limit</p>
          <p className="f16">
            Your current weekly spend limit is{" "}
            <span>${info.weeklySpendLimit.toLocaleString("au")}</span>{" "}
            <span>(Remaining: ${info.remaining.toLocaleString("au")} )</span>
          </p>
          <div
            className="btn style01 sm edit"
            onClick={() => handleClickOpen("limit")}
          >
            Edit
          </div>
        </div>

        <div className="item">
          <p className="f24">Notification Settings</p>
          <p className="f16">Edit your notification settings.</p>
          <div
            className="btn style01 sm edit"
            onClick={() => handleClickOpen("notification")}
          >
            Edit
          </div>
        </div>

        <CustModal open={open} setOpen={setOpen} onClose={handleClose}>
          {changeView(content)}
        </CustModal>
      </div>

      <Delete />
    </>
  );
};

export default Settings;

import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import InfiniteScroll from 'react-infinite-scroll-component'

// tool
import User from '../../tools/userInfo'

// constants
import Division from '../../constants/division'

// components
import NoData from '../common/noData'

interface Props {
  type: string
}

interface Playdate {
  drawnum: number
  playdate: string
}

interface Data {
  division: DivisionProps[]
  number: string[]
}

interface DivisionProps {
  ballset: BallSet
  prize: string
  winner: string
}

interface BallSet {
  white: number
  special: boolean
}

const LastWinningResult = ({ type }: Props) => {
  const { api } = User()
  const ref = useRef<Slider | null>(null)
  const selectRef = useRef<HTMLUListElement | null>(null)
  const [toggleDateLists, setToggleDateLists] = useState(false)
  const [selected, setSelected] = useState<Playdate>({
    drawnum: 0,
    playdate: '',
  })
  const [list, setList] = useState<Playdate[]>([])
  const [data, setData] = useState<Data>({
    division: [],
    number: [],
  })
  const [max, setMax] = useState(1)
  const [show, setShow] = useState<Playdate[]>([])

  function PrevArrow(props: any) {
    const clickFunc = () => {
      const { currentSlide } = props

      if (currentSlide !== 0) {
        const copy = [...list]
        ref.current?.slickPrev()
        const current = copy.reverse()[currentSlide - 1]
        setSelected(current)
      }

      setToggleDateLists(false)
    }

    return (
      <div className="btn arr prev" onClick={() => clickFunc()}>
        <span className="material-symbols-outlined">chevron_left</span>
      </div>
    )
  }

  function NextArrow(props: any) {
    const clickFunc = () => {
      const { currentSlide, slideCount } = props

      if (currentSlide + 1 !== slideCount) {
        const copy = [...list]
        ref.current?.slickNext()
        const current = copy.reverse()[currentSlide + 1]
        setSelected(current)
      }

      setToggleDateLists(false)
    }

    return (
      <div className="btn arr next" onClick={() => clickFunc()}>
        <span className="material-symbols-outlined">chevron_right</span>
      </div>
    )
  }

  useEffect(() => {
    if (type) {
      const ball = type === 'mega' ? 'mm' : 'pb'

      axios
        .get(`${api}/playdate/${ball}`, {
          headers: {
            'ngrok-skip-browser-warning': '69420',
          },
        })
        .then(({ data }) => {
          const Data: Playdate[] = data.data
          const twenty = Data.filter((_: Playdate, index: number) => index < 20)

          setList(Data)
          setSelected(Data[0])
          setMax(data.data.length)
          setShow(twenty)
        })
        .catch(response => {})
    }
  }, [type])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: list.length - 1,
    draggable: false,
    pauseOnHover: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: 'slider',
  }

  const specificAxios = () => {
    if (selected.drawnum) {
      const ball = type === 'mega' ? 'mm' : 'pb'

      axios
        .get(`${api}/prize/${ball}/${selected.drawnum}`, {
          headers: {
            'ngrok-skip-browser-warning': '69420',
          },
        })
        .then(({ data }) => {
          const Data = data.data
          const raw: DivisionProps[] = []
          const prizeArray = Data.prize.split(',')
          const winnerArray = Data.prizeCnt.split(',')

          for (let i = 0; i < 9; i++) {
            const dataset = {
              ballset: Division[i],
              prize: prizeArray[i],
              winner: winnerArray[i],
            }

            raw.push({ ...dataset })
          }

          setData({
            division: raw,
            number: data.data.winningNumber.split(','),
          })
        })
        .catch(response => {})
    }
  }

  useEffect(() => {
    specificAxios()
  }, [selected.drawnum])

  const changeSelected = (select: Playdate) => {
    const reverse = [...list].reverse()
    const current = reverse.findIndex(
      target => target.drawnum === select.drawnum
    )
    setSelected(select)
    setToggleDateLists(false)
    setShow(list.filter((_, index) => index < 20))
    ref.current?.slickGoTo(current, true)
  }

  const nextData = () => {
    if (show.length < max) {
      const now = [...show]
      const updated = now.length + 20

      for (let i = updated - 20; i < updated; i++) {
        const item = list[i]

        now.push(item)
      }

      setShow(now.filter(el => Boolean(el)))
    }
  }

  return (
    <div className="last-winning-numbers">
      <div className="inner">
        <div className="date-slide-wrap box style01">
          {list.length > 0 && (
            <div className="tit-area">
              <Slider ref={ref} {...settings}>
                {[...list].reverse().map(item => (
                  <div className="date-wrap" key={item.playdate}>
                    <p
                      className="f18 date"
                      onClick={() => {
                        setToggleDateLists(prev => !prev)
                        setShow(list.filter((_, index) => index < 20))
                      }}
                    >
                      {new Date(selected.playdate).toDateString()} (#
                      {item.drawnum})
                      <span className="material-symbols-outlined">
                        keyboard_arrow_down
                      </span>
                    </p>

                    {toggleDateLists && (
                      <ul className="date-lists" ref={selectRef}>
                        <InfiniteScroll
                          dataLength={show.length}
                          hasMore={show.length < max}
                          loader={<h4>Loading...</h4>}
                          next={() => nextData()}
                        >
                          {show.map(data => (
                            <li
                              key={data.drawnum}
                              onClick={() => changeSelected(data)}
                            >
                              {new Date(data.playdate).toDateString()} (#
                              {data.drawnum})
                            </li>
                          ))}
                        </InfiniteScroll>
                      </ul>
                    )}
                  </div>
                ))}
              </Slider>
            </div>
          )}
          <div className="cont">
            <div className="latest-winning-numbers">
              <div className="balls">
                {data.number.map((num, index) => (
                  <div
                    className={
                      index === data.number.length - 1 ? `ball ${type}` : 'ball'
                    }
                    key={`${selected.drawnum}_${num}_${index}`}
                  >
                    {num}
                  </div>
                ))}
              </div>
              {/* <p className="f18">Multiplier 3x</p> */}
            </div>

            {data.division.filter(div => Boolean(div.winner)).length > 0 ? (
              <div className="table-wrap">
                <table className="style01 center">
                  <colgroup>
                    <col width="15%" />
                    <col width="35%" />
                    <col width="25%" />
                    <col width="25%" />
                  </colgroup>
                  <tr>
                    <th>Division</th>
                    <th>Winning Combination</th>
                    <th>Prize (USD)</th>
                    <th>Winners</th>
                  </tr>

                  {data.division.map((rank, index) => (
                    <tr key={`${selected.drawnum}_${index}`}>
                      <td>Division{index + 1}</td>
                      <td>
                        <div className="balls sm">
                          {new Array(rank.ballset.white).fill(1).map((_, i) => (
                            <div
                              className="ball"
                              key={`${selected.drawnum}_${index}_${i}`}
                            />
                          ))}

                          {rank.ballset.special ? (
                            <div className={`ball ${type}`} />
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <td>$ {Number(rank.prize).toLocaleString('au')}</td>
                      <td>{Number(rank.winner).toLocaleString('au')}</td>
                    </tr>
                  ))}
                </table>
              </div>
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LastWinningResult

import { useState, useEffect } from 'react'
import axios from 'axios'

// components
import Login from './login'
import Drawer from '@mui/material/Drawer'

// constants
import {
  TopUnLogged,
  TopLogged,
  Menu,
  DrawerMenu,
} from '../../constants/headerMenu'

// tools
import User from '../../tools/userInfo'
import Swal from 'sweetalert2'
import { reset } from '../../tools/zustandUpdate'

// img
import Logo from '../../img/common/logo.svg'
import MegaLogo from '../../img/common/megamillions-logo.png'
import PowerLogo from '../../img/common/powerball-logo.png'

const Header = () => {
  const { api, token, setToken, setTotalInfo } = User()
  const [open, setOpen] = useState(false)

  const topFunction = (loc: string) => {
    switch (loc) {
      case 'login':
        setOpen(true)
        break

      case 'person_add':
        location.href = '/signup'
        break

      case 'logout':
        logoutAxios()
        break

      case 'account_circle':
        location.href = '/mypage'
    }
  }

  useEffect(() => {
    if (token) {
      const payload = JSON.parse(atob(token.split('.')[1]))
      const origin = payload.iss

      if (origin.includes('/login') || origin.includes('/refresh')) {
        const expire = payload.exp
        const diff = new Date().getTimezoneOffset() + 540
        const time = new Date().getTime()
        const now = (time - (time % 1000)) / 1000
        const converted = now + diff * 60

        if (converted > expire) {
          axios
            .get(`${api}/auth/me`, {
              headers: {
                'ngrok-skip-browser-warning': '69420',
                Authorization: 'bearer ' + token,
              },
            })
            .catch(({ response }) => {
              axios
                .get(`${api}/auth/refresh`, {
                  headers: {
                    'ngrok-skip-browser-warning': '69420',
                    Authorization: 'bearer ' + response.data.data.refresh_token,
                  },
                })
                .then(({ data }) => {
                  setToken(data.data.token)
                })
                .catch(error => console.log(error))
            })
        }
      }
    }
  }, [])

  const logoutAxios = () => {
    axios
      .post(
        `${api}/logout`,
        {},
        {
          headers: {
            'ngrok-skip-browser-warning': '69420',
            Authorization: 'bearer ' + token,
          },
        }
      )
      .then(({ data }) => {
        if (data.message === 'success') {
          Swal.fire({
            title: 'You have logged out successfully',
            icon: 'success',
            allowOutsideClick: false,
            didClose: () => {
              setToken('')
              reset(setTotalInfo)
              location.href = '/'
            },
          })
        }
      })
  }

  const Auth = token ? TopLogged : TopUnLogged

  const [toggleOpen, setToggleOpen] = useState(false)

  const toggleDrawer = (newOpen: boolean) => () => {
    setToggleOpen(newOpen)
  }

  return (
    <header className="header">
      <div className="top-area">
        <div className="inner flex">
          <img
            src={Logo}
            alt="LOTTERY CLUSTER"
            className="logo btn"
            onClick={() => {
              window.location.href = '/'
            }}
          />

          <nav className="user-nav">
            <ul className="flex">
              {Auth.map(item => (
                <li
                  key={item.id}
                  className="flex f16"
                  onClick={() => topFunction(item.icon)}
                >
                  {item.text}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      <div className="bottom-area">
        <div className="inner flex">
          <nav className="gnb">
            <ul className="flex">
              {Menu.map(item => (
                <a
                  href={item.link}
                  key={item.id}
                  className={`f16 ${
                    location.pathname === item.link ? 'current' : ''
                  }`}
                >
                  {item.text}
                </a>
              ))}
            </ul>
          </nav>
          <nav className="toggle-gnb">
            <div className="trigger btn" onClick={toggleDrawer(true)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <Drawer
              anchor="right"
              open={toggleOpen}
              onClose={toggleDrawer(false)}
            >
              <ul className="drawer-nav">
                <a href="/mega" className="game mega">
                  <img src={MegaLogo} alt="Mega Millions" />
                  <p className="f18">Mega Millions</p>
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </a>
                <a href="/power" className="game power">
                  <img src={PowerLogo} alt="Powerball" />
                  <p className="f18">Powerball</p>
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </a>
                {DrawerMenu.map(item => (
                  <a
                    key={item.id}
                    href={item.link}
                    className={`f16 menu ${
                      location.pathname === item.link ? 'current' : ''
                    }`}
                  >
                    {item.text}
                    <span className="material-symbols-outlined">
                      chevron_right
                    </span>
                  </a>
                ))}
              </ul>
            </Drawer>
          </nav>
        </div>
      </div>

      <Login open={open} setOpen={setOpen} />
    </header>
  )
}

export default Header

import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import TextField from '@mui/material/TextField'

// tools
import User from '../../tools/userInfo'
import { RandomNumber } from '../../tools/random'
import { NumberGenerator } from '../../tools/numberGenerator'
import NumberOnly from '../../tools/numberOnly'
import { update } from '../../tools/zustandUpdate'

interface GameInfo {
  drawnum: number
  jackpot: number
  playdate: string
  orderTime: string
}

interface QuickProps {
  type: string
  setLogin: Dispatch<SetStateAction<boolean>>
  gameInfo: GameInfo
}

interface Ball {
  white: number
  special: number
}

interface BallSet {
  [key: string]: Ball
  mega: Ball
  power: Ball
}

interface Nums {
  num: number[]
  type: string
}

const QuickType = [1, 3, 6, 9, 12, 15, 20]

const BallSet: BallSet = {
  mega: {
    white: 70,
    special: 25,
  },
  power: {
    white: 69,
    special: 26,
  },
}

const QuickPlay = ({ type, setLogin, gameInfo }: QuickProps) => {
  const { info, token, api, setTotalInfo } = User()
  const [game, setGame] = useState<Nums[]>([])
  const [count, setCount] = useState(21)

  const countChange = (value: string) => {
    const number = Number(NumberOnly(value))

    if (number > 100 - game.length) {
      Swal.fire({
        title: 'Error',
        text: 'You cannot exceed 100 games per purchase.',
        icon: 'error',
        allowOutsideClick: false,
        didClose: () => setCount(1),
      })
    }

    if (number < 101) {
      setCount(Number(NumberOnly(value)))
    }
  }

  const addQuick = (amount: number) => {
    const copy = [...game]
    const set = BallSet[type]

    if (amount + copy.length > 100) {
      Swal.fire({
        title: 'Error',
        text: 'You cannot exceed 100 games per purchase.',
        icon: 'error',
        allowOutsideClick: false,
      })
    } else {
      for (let i = 0; i < amount; i++) {
        const white = NumberGenerator(set.white, [])
        const special = RandomNumber(set.special)
        const result = [...white, special]

        copy.push({ num: result, type: 'A' })
      }

      setGame([...copy])
    }
  }

  const countRemove = () => {
    if (count > 1) {
      setCount(current => current - 1)
    }
  }

  const countAdd = () => {
    if (count < 100 - game.length) {
      setCount(current => current + 1)
    }
  }

  const singleDelete = (index: number) => {
    const copy = [...game]

    copy.splice(index, 1)

    setGame([...copy])
  }

  const singleRefresh = (index: number) => {
    const copy = [...game]
    const set = BallSet[type]
    const white = NumberGenerator(set.white, [])
    const special = RandomNumber(set.special)
    const result = [...white, special]

    copy[index] = { num: result, type: 'A' }

    setGame([...copy])
  }

  const checkout = () => {
    if (token) {
      axios
        .post(
          `${api}/order`,
          {
            gameType: type === 'mega' ? 'MM' : 'PB',
            drawnum: gameInfo.drawnum,
            playdate: gameInfo.playdate,
            gameCnt: game.length,
            nums: game,
            cash: 7 * game.length,
          },
          {
            headers: {
              'ngrok-skip-browser-warning': '69420',
              Authorization: 'bearer ' + token,
            },
          }
        )
        .then(({ data }) => {
          if (data.message === 'success') {
            update(api, token, setTotalInfo)

            Swal.fire({
              title: 'Success',
              text: 'Purchase has been successfully completed',
              icon: 'success',
              allowOutsideClick: false,
              didClose: () => location.reload(),
            })
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: 'Error',
            text: response.data.message,
            icon: 'error',
            allowOutsideClick: false,
          })
        })
    } else {
      setLogin(true)
      sessionStorage.setItem('ball', JSON.stringify(game))
    }
  }

  useEffect(() => {
    const ball = sessionStorage.getItem('ball')
    if (ball) {
      const parse = JSON.parse(ball)

      setGame(parse)

      setTimeout(() => sessionStorage.clear(), 50)
    }
  }, [])

  return (
    <div className="quick-play">
      <div className="step picks">
        {QuickType.map(line => (
          <div
            className="item box style01 flex"
            key={line}
            onClick={() => addQuick(line)}
          >
            <span className="material-symbols-outlined">add_circle</span>
            <p className="f24">{line} Quick Play</p>
            <p className="f18">$ {7 * line}</p>
          </div>
        ))}

        <div className="item box style01 flex">
          <div className="count flex">
            <div className="btn style03 add" onClick={() => countRemove()}>
              <span className="material-symbols-outlined">remove</span>
            </div>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="21"
              type="tel"
              sx={{ width: '64px' }}
              value={count}
              onChange={({ target }) => countChange(target.value)}
              disabled={game.length === 100}
            />
            <div className="btn style03 add" onClick={() => countAdd()}>
              <span className="material-symbols-outlined">add</span>
            </div>
          </div>
          <p className="f24">{count} Quick Play</p>
          <p className="f18">
            <span>Total:</span> ${7 * count}
          </p>
          <div className="btn style01 sm" onClick={() => addQuick(count)}>
            Add
          </div>
        </div>
      </div>

      {game.length > 0 && (
        <div className="step checkout box style01">
          <div className="tit-area">
            <h3 className="f40">Checkout</h3>
            <div className="btn">
              <span
                className="btn material-symbols-outlined"
                onClick={() => setGame([])}
              >
                delete
              </span>
            </div>
          </div>

          <div className="cont">
            <div className="selected-area">
              <div className="lists">
                {game.map((item, index) => (
                  <div className="list" key={`Game${index}`}>
                    <p className="f18">
                      {index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}
                    </p>

                    <div className="balls">
                      {item.num.map((num, i) => (
                        <div
                          className={
                            i + 1 === item.num.length ? `ball ${type}` : 'ball'
                          }
                          key={`Game${index}_${num}_${i}`}
                        >
                          {num}
                        </div>
                      ))}
                    </div>

                    <div className="btn-wrap">
                      <span
                        className="btn material-symbols-outlined"
                        onClick={() => singleRefresh(index)}
                      >
                        restart_alt
                      </span>
                      <span
                        className="btn material-symbols-outlined"
                        onClick={() => singleDelete(index)}
                      >
                        delete
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="pay-info">
              <div className="balance-area flex">
                <p>Available Balance</p>
                <p className="f24">$ {info.cash}</p>
              </div>
              <div className="line-area flex">
                <p className="f24 lines">{game.length} Lines</p>
                <div className="total flex">
                  <p className="f18">Total : </p>
                  <p className="f40">$ {7 * game.length}</p>
                </div>
              </div>
              <div className="btn style01" onClick={() => checkout()}>
                Checkout
                <span className="material-symbols-outlined">chevron_right</span>
              </div>
            </div>
          </div>

          {/* <div className="bottom-area">
            <p className="f18">Total :</p>
            <p className="f24">$ {(game.length * 7).toLocaleString('au')}</p>
            <div className="btn style01" onClick={() => checkout()}>
              Checkout
            </div>
          </div> */}
        </div>
      )}

      {/* <div className="company-account-info">
    <div className="tit-area">
      <h3 className="f24">Account Informations</h3>
      <img src={AccountIco} alt="" className="ico" />
    </div>
    <div className="cont">
      <div className="info">
        <img src={BankImg} alt="Line bank" />
        <ul>
          <li>
            <p className="tit">Deposit Amount : </p>
            <p className="txt f24">$ 240,000</p>
          </li>
          <li>
            <p className="tit">Account Number : </p>
            <p className="txt f24">1234 5678 9000</p>
          </li>
          <li>
            <p className="tit">Account Holder : </p>
            <p className="txt">The Lottery Cluster</p>
          </li>
        </ul>
      </div>
      <p className="exp">
        After making a deposit via bank transfer, you can check your
        transaction details on My Page.
      </p>
    </div>
  </div> */}
    </div>
  )
}

export default QuickPlay

import { useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

// components
import Wrapper from "../components/common/wrapper";

// tools
import User from "../tools/userInfo";

interface FAQ {
  category: string;
  content: string;
  created_at: string;
  idx: number;
  sort: number;
  subject: string;
  type: string;
  updated_at: string;
}

const Faq = () => {
  const { api } = User();
  const [expanded, setExpanded] = useState<number>(0);
  const [category, setCateogory] = useState([]);
  const [menu, setMenu] = useState("");
  const [faq, setFaq] = useState<FAQ[]>([]);

  const handleChange =
    (panel: number) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : 0);
    };

  const { search } = location;

  useEffect(() => {
    const menu = search.replace("?", "").split("=")[1]
      ? search.replace("?", "").split("=")[1]
      : "general";

    setMenu(menu);

    axios
      .get(`${api}/support/faq/categories`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
        },
      })
      .then(({ data }) => setCateogory(data.data.category));

    axios
      .get(`${api}/support/faq/categories/${menu}`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
        },
      })
      .then(({ data }) => setFaq(data.data.data));
  }, []);

  const TextConvert = (value: string) => {
    switch (value) {
      case "general":
        return "General";
        break;

      case "started":
        return "Getting Started";
        break;

      case "works":
        return "How It Works";
        break;
    }
  };

  const handleCate = (event: SelectChangeEvent) => {
    location.href = `/faq?tab=${event.target.value}`;
  };

  const matches = useMediaQuery("(max-width:768px)");

  return (
    <Wrapper>
      <div className="sub faq">
        <div className="top-info-area inner">
          <div className="txt-area">
            <p className="f40">FAQ</p>
            <p className="f18 txt">Questions we are often asked.</p>
          </div>
        </div>

        <div className="snb-cont-wrap">
          <div className="inner">
            {matches ? (
              <FormControl fullWidth className="select">
                <InputLabel id="category">Categories</InputLabel>
                <Select
                  labelId="category"
                  id="category"
                  label="Categories"
                  onChange={handleCate}
                  value={menu}
                >
                  {category.map((item) => (
                    <MenuItem key={item} value={item}>
                      {TextConvert(item)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <div className="snb-area">
                <p className="f18 tit">Categories</p>
                <ul className="snb">
                  {category.map((item) => (
                    <li
                      className={menu === item ? "current" : ""}
                      key={item}
                      onClick={() => (location.href = `/faq?tab=${item}`)}
                    >
                      {TextConvert(item)}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="cont">
              <h3 className="f24">{TextConvert(menu)}</h3>
              {faq.map((item) => (
                <Accordion
                  key={item.idx}
                  expanded={expanded === item.idx}
                  onChange={handleChange(item.idx)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>
                      <span className="material-symbols-outlined">
                        expand_more
                      </span>
                      {item.subject}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div
                        className="acco-details"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Faq;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: "#f5f7fa;",
  "& p": {
    display: "flex",
    alignItem: "center",
    color: "#8c8ca1",
  },
  "& .Mui-expanded p": {
    color: "#5399f5",
    fontWeight: "bold",
  },
  "& .Mui-expanded span": {
    transform: "rotate(180deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

// components
import { useEffect, useState } from 'react'
import axios from 'axios'

// components
import Wrapper from '../components/common/wrapper'
import CheckNumber from '../components/main/checkNumber'
import Game from '../components/main/game'
import EventSlider from '../components/main/eventSlider'
import News from '../components/main/news'
import { MainBg } from '../components/main/mainBg'

// tools
import User from '../tools/userInfo'

export interface LottoProps {
  auDrawTime: string
  auOrderTime: string
  currency: string
  dayWeek: number
  drawnum: number
  exchange: string
  initial: number
  isDrawdate: number
  jackpot: number
  playdate: string
  type: string
  usDrawTime: string
}

const Main = () => {
  const { api } = User()
  const [lottery, setLottery] = useState<LottoProps[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    axios
      .get(`${api}/purchase`, {
        headers: {
          'ngrok-skip-browser-warning': '69420',
        },
      })
      .then(({ data }) => {
        if (data.message === 'success') {
          setLottery(data.data)
          setTimeout(() => setLoading(false), 1000)
        }
      })
      .catch(response => {})
  }, [])

  return (
    <Wrapper>
      <MainBg />
      <div className="main">
        <EventSlider loading={loading} lottery={lottery} />
        <Game loading={loading} lottery={lottery} />
        <CheckNumber />
        <News />
      </div>
    </Wrapper>
  )
}

export default Main

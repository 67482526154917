import { RandomNumber } from './random'

export const NumberGenerator = (max: number, fixed: number[]) => {
  const list = new Set<number>()
  const set: number[] = []

  for (let i = 0; i < fixed.length; i++) {
    list.add(fixed[i])
  }

  while (5 > list.size) {
    list.add(RandomNumber(max))
  }

  for (const num of Array.from(list)) {
    set.push(num)
  }

  return set.sort(function (a, b) {
    return a - b
  })
}

export const NumberGeneratorOption = (max: number, lastWin: number[]) => {
  const list = new Set<number>()
  const set: number[] = []
  const copy: number[] = [...lastWin]
  copy.pop()

  while (5 > list.size) {
    const generated = RandomNumber(max)
    if (!copy.includes(generated)) {
      list.add(generated)
    }
  }

  for (const num of Array.from(list)) {
    set.push(num)
  }

  return set.sort(function (a, b) {
    return a - b
  })
}

import { useState, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { PieChart } from '@mui/x-charts/PieChart'
import axios from 'axios'

// type
import type { Pie } from '../../pages/stat'

// tools
import User from '../../tools/userInfo'

interface Combination {
  id: number
  value: number
  label: string
}

interface List {
  drawnum: number
  high: number
  low: number
  numbers: string
  playdate: string
}

const HighLow = ({ type, month }: Pie) => {
  const { api } = User()
  const [combination, setCombination] = useState<Combination[]>([
    { id: 1, value: 0, label: 'All Low' },
    {
      id: 2,
      value: 0,
      label: 'Single High and 5 Low',
    },
    {
      id: 3,
      value: 0,
      label: '2 High and 4 Low',
    },
    { id: 4, value: 0, label: '3 High and 3 Low' },
    {
      id: 5,
      value: 0,
      label: '2 Low and 4 High',
    },
    {
      id: 6,
      value: 0,
      label: 'Single Low and 5 High',
    },
    { id: 7, value: 0, label: 'All High' },
  ])
  const [list, setList] = useState<List[]>([])
  const [total, setTotal] = useState<number>(0)

  const dataAxios = () => {
    const Type = type === 'mega' ? 'mm' : 'pb'
    const today = new Date()
    const startDate = new Date(today)
    startDate.setMonth(today.getMonth() - Number(month))

    const toMonth =
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1
    const sMonth =
      startDate.getMonth() + 1 < 10
        ? `0${startDate.getMonth() + 1}`
        : startDate.getMonth() + 1

    const start = `${startDate.getFullYear()}-${sMonth}-${startDate.getDate()}`
    const end = `${today.getFullYear()}-${toMonth}-${today.getDate()}`

    axios
      .get(`${api}/number/highlow?type=${Type}&sDate=${start}&eDate=${end}`, {
        headers: {
          'ngrok-skip-browser-warning': '69420',
        },
      })
      .then(({ data }) => {
        const copiedCombi = [...combination]
        for (let i = 0; i < data.data.combination.length; i++) {
          const item = copiedCombi[i]
          item.value = data.data.combination[i]
          copiedCombi[i] = { ...item }
        }
        setCombination(copiedCombi)
        setTotal(data.data.totalCount)
        setList(data.data.data)
      })
  }

  useEffect(() => dataAxios(), [month])

  const matches = useMediaQuery('(max-width:480px)')
  return (
    <div className="stat-result low-high">
      <p className="f16 exp">
        You can check the ratio and pattern of{' '}
        <span
          className="material-symbols-outlined"
          style={{
            color: '#0063f7',
            fontSize: '13px',
            rotate: '180deg',
          }}
        >
          change_history
        </span>
        Low(1~35) and{' '}
        <span
          className="material-symbols-outlined"
          style={{ color: '#ff3b3b', fontSize: '13px' }}
        >
          change_history
        </span>
        High(36~
        {type === 'mega' ? '70' : '69'}) white ball numbers as well as the ratio
        and pattern of&nbsp;
        <span
          className="material-symbols-outlined"
          style={{
            color: '#0063f7',
            fontSize: '13px',
            rotate: '180deg',
          }}
        >
          change_history
        </span>
        Low(1~13)와{' '}
        <span
          className="material-symbols-outlined"
          style={{ color: '#ff3b3b', fontSize: '13px' }}
        >
          change_history
        </span>
        High(14~
        {type === 'mega' ? '25' : '26'}).
      </p>

      <div className="item box style01">
        <h4 className="f24">High and Low Number Analysis</h4>
        <div className="table-wrap">
          <table className="style01">
            <tr>
              <th>High and Low Ratio</th>
              <th>Ratio Graph</th>
              <th>Frequency</th>
              <th>Winning Probability</th>
            </tr>

            {combination.map(item => (
              <tr key={item.id}>
                <td>{item.label}</td>
                <td>
                  <div
                    className="bar"
                    style={{
                      width: `${((item.value / total) * 100).toFixed(1)}%`,
                    }}
                  />
                </td>
                <td>{item.value} times</td>
                <td>{((item.value / total) * 100).toFixed(1)}%</td>
              </tr>
            ))}
          </table>
        </div>
      </div>

      <div className="item box style01">
        <h4 className="f24">Pie Chart of High and Low Number Combination</h4>
        <PieChart
          margin={
            matches
              ? { top: 0, bottom: 150, left: 0, right: 0 }
              : { top: 0, bottom: 0, left: 0, right: 250 }
          }
          series={[
            {
              data: combination,
            },
          ]}
          height={350}
          slotProps={
            matches
              ? {
                  legend: {
                    position: { vertical: 'bottom', horizontal: 'left' },
                    padding: 0,
                    itemMarkWidth: 20,
                    itemMarkHeight: 2,
                    markGap: 3,
                    itemGap: 1,
                  },
                }
              : {
                  legend: {
                    position: { vertical: 'middle', horizontal: 'right' },
                    padding: 0,
                    itemMarkWidth: 20,
                    itemMarkHeight: 2,
                    markGap: 3,
                    itemGap: 2,
                  },
                }
          }
        />
      </div>

      <div className="item box style01">
        <h4 className="f24">High and Low Number Analysis Table</h4>
        <div className="table-wrap">
          <table className="style01">
            <tr>
              <th>Draw Number</th>
              <th>Winning Numbers</th>
              <th>0:6</th>
              <th>1:5</th>
              <th>2:4</th>
              <th>3:3</th>
              <th>4:2</th>
              <th>5:1</th>
              <th>6:0</th>
            </tr>

            {list.map(item => {
              const ball = item.numbers.split(',')
              const highLowChecker = (target: string, index: number) => {
                const number = Number(target)

                if (index === ball.length - 1) {
                  if (number < 14) {
                    return 'mega row'
                  } else {
                    return 'mega high'
                  }
                } else {
                  if (number < 36) {
                    return 'row'
                  } else {
                    return 'high'
                  }
                }
              }

              return (
                <tr key={item.drawnum}>
                  <td>#{item.drawnum}</td>
                  <td>
                    <div className="balls row-high sm">
                      {ball.map((num, index) => (
                        <div
                          className={`ball ${highLowChecker(num, index)}`}
                          key={`${item.drawnum}_${num}_${index}`}
                        >
                          <span className="material-symbols-outlined">
                            change_history
                          </span>
                          <span className="num">{num}</span>
                        </div>
                      ))}
                    </div>
                  </td>

                  {combination.map((com, index) =>
                    index === item.high ? (
                      <td key={com.id}>
                        <span className="material-symbols-outlined">check</span>
                      </td>
                    ) : (
                      <td key={com.id} />
                    )
                  )}
                </tr>
              )
            })}
          </table>
        </div>
      </div>
    </div>
  )
}

export default HighLow

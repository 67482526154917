import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FloatingNav = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const scrollHandle = () => {
      const floatingNav = document.querySelector(
        ".floating-nav"
      ) as HTMLElement;
      const footer = document.querySelector(".footer") as HTMLElement;

      if (!floatingNav || !footer) return;

      const pageHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );

      const scrollTop = window.scrollY || window.pageYOffset;
      const footerTop = footer.offsetTop;
      const footerHeight = footer.offsetHeight;

      if (scrollTop + window.innerHeight >= pageHeight) {
        floatingNav.style.position = "fixed";
        floatingNav.style.bottom = `${footerHeight + 64}px`;
      } else {
        floatingNav.style.position = "fixed";
        floatingNav.style.bottom = "32px";
      }

      if (window.scrollY > 50) {
        floatingNav.classList.add("scroll");
      } else {
        floatingNav.classList.remove("scroll");
      }
    };

    window.addEventListener("scroll", scrollHandle);

    return () => {
      window.removeEventListener("scroll", scrollHandle);
    };
  }, []);

  return (
    <div className="floating-nav">
      <Link to="/email" className="item btn">
        <span className="material-symbols-outlined">mail</span>
        <span className="txt">Email Us</span>
      </Link>

      <Link to="/faq" className="item btn">
        <span className="material-symbols-outlined">contact_support</span>
        <span className="txt">FAQ</span>
      </Link>

      <div className="btn top" onClick={scrollToTop}>
        <span className="material-symbols-outlined">arrow_upward_alt</span>
      </div>
    </div>
  );
};

export default FloatingNav;

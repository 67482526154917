import { useState, MouseEvent, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Dayjs } from 'dayjs'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import Swal from 'sweetalert2'

// components
import Wrapper from '../components/common/wrapper'
import Login from '../components/common/login'
import SignUpComplete from './signUpComplete'

// tools
import User from '../tools/userInfo'
import NumberOnly from '../tools/numberOnly'

// img
import Apple from '../img/common/apple-ico.svg'
import Google from '../img/common/google-ico.svg'
import Facebook from '../img/common/facebook-ico.svg'

interface Info {
  email: string
  password: string
  gender: string
  firstName: string
  lastName: string
  phone: string
  birth: string
  unit: string
  number: string
  address: string
  city: string
  country: string
  notify: string
  marketing: string
  provider: string
}

const SignUp = () => {
  const { api } = User()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [info, setInfo] = useState<Info>({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phone: '',
    birth: '',
    gender: '',
    unit: '',
    number: '',
    address: '',
    city: '',
    country: '',
    notify: 'Y',
    marketing: 'Y',
    provider: '',
  })
  const [social, setSocial] = useState(false)
  const [agree, setAgree] = useState(false)

  const handleClickShowPassword = () => setShowPassword(show => !show)

  const handleMouseDownPassword = (event: MouseEvent) => {
    event.preventDefault()
  }

  const handleData = (key: string, value: string) => {
    if (key === 'number' || key === 'phone') {
      setInfo(prev => ({ ...prev, [key]: NumberOnly(value) }))
    } else {
      setInfo(prev => ({ ...prev, [key]: value }))
    }
  }

  const confirm = document.getElementById('confirm') as HTMLInputElement

  useEffect(() => {
    const socialCheck = sessionStorage.getItem('social')
    if (socialCheck) {
      const parse = JSON.parse(socialCheck)

      setSocial(true)
      setInfo(prev => ({
        ...prev,
        ...parse,
      }))

      setTimeout(() => sessionStorage.clear(), 50)
    }
  }, [])

  const checkout = () => {
    if (agree) {
      if (confirm.value === info.email) {
        if (info.password.length > 7 && info.password.length < 21) {
          if (!info.gender) {
            handleData('gender', 'other')
          }

          if (info.firstName) {
            if (info.lastName) {
              if (info.phone) {
                if (info.birth) {
                  if (info.address) {
                    if (info.city) {
                      if (info.country) {
                        register()
                      } else {
                        Swal.fire({
                          title: 'Select your country',
                          icon: 'error',
                          allowOutsideClick: false,
                        })
                      }
                    } else {
                      Swal.fire({
                        title: 'City is required',
                        icon: 'error',
                        allowOutsideClick: false,
                      })
                    }
                  } else {
                    Swal.fire({
                      title: 'Address is required',
                      icon: 'error',
                      allowOutsideClick: false,
                    })
                  }
                } else {
                  Swal.fire({
                    title: 'Date of birth is required',
                    icon: 'error',
                    allowOutsideClick: false,
                  })
                }
              } else {
                Swal.fire({
                  title: 'Phone number is required',
                  icon: 'error',
                  allowOutsideClick: false,
                })
              }
            } else {
              Swal.fire({
                title: 'Last name is required',
                icon: 'error',
                allowOutsideClick: false,
              })
            }
          } else {
            Swal.fire({
              title: 'First name is required',
              icon: 'error',
              allowOutsideClick: false,
            })
          }
        } else {
          Swal.fire({
            title: 'Password must be between 8 ~ 20 characters',
            icon: 'error',
            allowOutsideClick: false,
          })
        }
      } else {
        Swal.fire({
          title: 'Email and Confirm Email do not match',
          icon: 'error',
          allowOutsideClick: false,
        })
      }
    } else {
      Swal.fire({
        title: 'You must agree to the terms and conditions',
        icon: 'error',
        allowOutsideClick: false,
      })
    }
  }

  const register = () => {
    axios
      .post(`${api}/register`, info, {
        headers: {
          'ngrok-skip-browser-warning': '69420',
        },
      })
      .then(({ data }) => {
        if (data.message === 'success') {
          navigate(`/welcome`)
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.message,
          icon: 'error',
          allowOutsideClick: false,
        })
      })
  }

  const googleLogin = useGoogleLogin({
    scope: 'email profile',
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${location.origin}/auth/google`,
    state: location.href,
  })

  const socialCheckout = () => {
    if (agree) {
      if (!info.gender) {
        handleData('gender', 'other')
      }

      if (info.firstName) {
        if (info.lastName) {
          if (info.phone) {
            if (info.birth) {
              if (info.address) {
                if (info.city) {
                  if (info.country) {
                    socialRegister()
                  } else {
                    Swal.fire({
                      title: 'Select your country',
                      icon: 'error',
                      allowOutsideClick: false,
                    })
                  }
                } else {
                  Swal.fire({
                    title: 'City is required',
                    icon: 'error',
                    allowOutsideClick: false,
                  })
                }
              } else {
                Swal.fire({
                  title: 'Address is required',
                  icon: 'error',
                  allowOutsideClick: false,
                })
              }
            } else {
              Swal.fire({
                title: 'Date of birth is required',
                icon: 'error',
                allowOutsideClick: false,
              })
            }
          } else {
            Swal.fire({
              title: 'Phone number is required',
              icon: 'error',
              allowOutsideClick: false,
            })
          }
        } else {
          Swal.fire({
            title: 'Last name is required',
            icon: 'error',
            allowOutsideClick: false,
          })
        }
      } else {
        Swal.fire({
          title: 'First name is required',
          icon: 'error',
          allowOutsideClick: false,
        })
      }
    } else {
      Swal.fire({
        title: 'You must agree to the terms and conditions',
        icon: 'error',
        allowOutsideClick: false,
      })
    }
  }

  const socialRegister = () => {
    axios
      .post(`${api}/register`, info, {
        headers: {
          'ngrok-skip-browser-warning': '69420',
        },
      })
      .then(({ data }) => {
        if (data.message === 'success') {
          navigate(`/welcome`)
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.message,
          icon: 'error',
          allowOutsideClick: false,
        })
      })
  }

  return (
    <Wrapper>
      {location.pathname === '/signup' ? (
        <div className="signup">
          <div className="inner">
            <div className="tit-area">
              <h3 className="f40">Sign Up</h3>
              <div className="signup-login-area">
                <p className="f18">Sign up with your email to get started!</p>
                <p className="f18 signup-login">
                  Already have an account?
                  <a href="#" className="btn" onClick={() => setOpen(true)}>
                    Log In
                  </a>
                </p>
              </div>
            </div>

            <div className="cont">
              <table className="form">
                <colgroup>
                  <col width="25%" />
                  <col width="75%" />
                </colgroup>
                <tr>
                  <th className="required">Email</th>
                  <td>
                    <div className="email-wrap">
                      <TextField
                        type="email"
                        label="Email"
                        variant="outlined"
                        sx={{ width: '60%' }}
                        value={info.email}
                        onChange={({ target }) =>
                          handleData('email', target.value)
                        }
                        disabled={social}
                      />
                      <div className="sns-area">
                        {/* <img
                          src={Facebook}
                          alt="Facebook Login"
                          className="btn"
                        /> */}
                        <img
                          src={Google}
                          alt="Google Login"
                          className="btn"
                          onClick={() => googleLogin()}
                        />
                        {/* <img src={Apple} alt="Apple Login" className="btn" /> */}
                      </div>
                    </div>
                  </td>
                </tr>

                {!social && (
                  <>
                    <tr>
                      <th className="required">Confirm Email</th>
                      <td>
                        <TextField
                          id="confirm"
                          type="email"
                          label="Confirm Email"
                          variant="outlined"
                          fullWidth
                        />
                      </td>
                    </tr>

                    <tr>
                      <th className="required">Password</th>
                      <td>
                        <FormControl sx={{ width: 1 }}>
                          <InputLabel htmlFor="outlined-adornment-password">
                            Password
                          </InputLabel>

                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <span className="material-symbols-outlined">
                                      visibility
                                    </span>
                                  ) : (
                                    <span className="material-symbols-outlined">
                                      visibility_off
                                    </span>
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                            value={info.password}
                            onChange={({ target }) =>
                              handleData('password', target.value)
                            }
                          />
                        </FormControl>
                      </td>
                    </tr>
                  </>
                )}

                <tr>
                  <th className="ms">Gender</th>
                  <td>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={info.gender}
                      onChange={({ target }) =>
                        handleData('gender', target.value)
                      }
                    >
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                  </td>
                </tr>
                <tr>
                  <th className="required">First Name</th>
                  <td>
                    <TextField
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      value={info.firstName}
                      onChange={({ target }) =>
                        handleData('firstName', target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th className="required">Last Name</th>
                  <td>
                    <TextField
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      value={info.lastName}
                      onChange={({ target }) =>
                        handleData('lastName', target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th className="required">Phone Number</th>
                  <td>
                    <TextField
                      type="tel"
                      label="Phone Number"
                      variant="outlined"
                      fullWidth
                      value={info.phone}
                      onChange={({ target }) =>
                        handleData('phone', target.value)
                      }
                      inputProps={{
                        maxLength: 10,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="required ms">Date of Birth</th>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        slotProps={{ textField: { fullWidth: true } }}
                        onChange={(e: Dayjs | null) => {
                          if (e) handleData('birth', e.format('YYYYMMDD'))
                        }}
                      />
                    </LocalizationProvider>
                  </td>
                </tr>
                <tr>
                  <th rowSpan={4} className="ms">
                    Address
                  </th>
                  <td className="flex">
                    <TextField
                      label="Unit/Flat"
                      variant="outlined"
                      sx={{ width: '50%' }}
                      value={info.unit}
                      onChange={({ target }) =>
                        handleData('unit', target.value)
                      }
                    />

                    <TextField
                      type="tel"
                      label="Street Number"
                      variant="outlined"
                      sx={{ width: '50%' }}
                      value={info.number}
                      onChange={({ target }) =>
                        handleData('number', target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField
                      label="Address"
                      variant="outlined"
                      fullWidth
                      value={info.address}
                      onChange={({ target }) =>
                        handleData('address', target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField
                      label="City / Town or Suburb"
                      variant="outlined"
                      fullWidth
                      value={info.city}
                      onChange={({ target }) =>
                        handleData('city', target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Country
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={info.country}
                        label="Select Country"
                        onChange={({ target }) =>
                          handleData('country', target.value)
                        }
                      >
                        <MenuItem value="AU">Australia</MenuItem>
                        <MenuItem value="NZ">New Zealand</MenuItem>
                      </Select>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <th className="ms">Notifications</th>
                  <td>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={({ target }) =>
                            handleData('notify', target.checked ? 'Y' : 'N')
                          }
                        />
                      }
                      label="SMS notifications"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={({ target }) =>
                            handleData('marketing', target.checked ? 'Y' : 'N')
                          }
                        />
                      }
                      label="App Push"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="line" />
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td>
                    <FormControlLabel
                      sx={{ alignItems: 'flex-start' }}
                      value=""
                      control={
                        <Checkbox
                          value={agree}
                          onChange={({ target }) => setAgree(target.checked)}
                          sx={{
                            marginTop: -1,
                          }}
                        />
                      }
                      label=" I have read The Lottery Cluster’s privacy policy and agree
                    to the terms and conditions."
                    />
                    <p className="f16" />
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td>
                    <a
                      href="#"
                      className="btn style01"
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (social) {
                          socialCheckout()
                        } else {
                          checkout()
                        }
                      }}
                    >
                      Create account Now
                    </a>
                  </td>
                </tr>
              </table>
              <div className="img-area" />
            </div>
          </div>
        </div>
      ) : location.pathname === '/welcome' ? (
        <SignUpComplete info={info} setOpen={setOpen} />
      ) : (
        <></>
      )}

      <Login open={open} setOpen={setOpen} />
    </Wrapper>
  )
}

export default SignUp

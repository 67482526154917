import { useState, Dispatch, SetStateAction } from 'react'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import dayjs, { Dayjs } from 'dayjs'
import axios from 'axios'
import Swal from 'sweetalert2'

// tools
import User from '../../tools/userInfo'
import NumberOnly from '../../tools/numberOnly'

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>
}

interface Info {
  [key: string | number]: string | number | null | undefined
  address?: string
  birth?: string
  cash?: number
  city?: string
  country?: string
  createdAt?: string
  email?: string
  firstName?: string
  gender?: string
  lastName?: string
  level?: number
  marketing?: string
  notify?: string
  number?: null | string | number
  phone?: string
  unit?: null | string
}

const PersonalInfo = ({ setOpen }: ModalContent) => {
  const { api, token } = User()
  const [info, setInfo] = useState<Info>(User().info)

  const handleData = (key: string, value: string) => {
    if (key === 'number' || key === 'phone') {
      setInfo(prev => ({ ...prev, [key]: NumberOnly(value) }))
    } else {
      setInfo(prev => ({ ...prev, [key]: value }))
    }
  }

  const original = User().info

  const notificationAxios = () => {
    const originalValues = Object.values(original)
    const infoValues = Object.values(info)
    const keys = Object.keys(info)

    const indexs = originalValues
      .map((item, index) => {
        if (!infoValues.includes(item)) return index
        else return -1
      })
      .filter(idx => idx !== -1)

    const revised: Info = {}

    for (let i = 0; i < indexs.length; i++) {
      const index = indexs[i]
      revised[keys[index]] = infoValues[index]
    }

    const dataKey = Object.keys(revised)

    const emptyFirst = dataKey.includes('firstName') && !revised.firstName
    const emptyLast = dataKey.includes('lastName') && !revised.lastName
    const emptyPhone = dataKey.includes('phone') && !revised.phone

    if (emptyFirst || emptyLast || emptyPhone) {
      Swal.fire({
        title: 'Error',
        text: 'There is an empty value on a required field.',
        icon: 'error',
        allowOutsideClick: false,
      })
    } else {
      axios
        .put(`${api}/auth/update`, revised, {
          headers: {
            'ngrok-skip-browser-warning': '69420',
            Authorization: 'bearer ' + token,
          },
        })
        .then(({ data }) => {
          if (data.message) {
            Swal.fire({
              title: 'Update Completed',
              icon: 'success',
              allowOutsideClick: false,
              didClose: () => location.reload(),
            })
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: 'Error',
            text: response.data.message,
            icon: 'error',
            allowOutsideClick: false,
          })
        })
    }
  }

  return (
    <>
      <div className="tit-area flex">
        <h3 className="f24">Personal Information Edit</h3>
        <span
          className="btn close material-symbols-outlined"
          onClick={() => setOpen(false)}
        >
          close
        </span>
      </div>

      <div className="cont">
        <table className="form">
          <colgroup>
            <col width="25%" />
            <col width="75%" />
          </colgroup>

          <tr>
            <th className="required">First Name</th>
            <td>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                value={info.firstName}
                onChange={({ target }) => handleData('firstName', target.value)}
              />
            </td>
          </tr>
          <tr>
            <th className="required">Last Name</th>
            <td>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                value={info.lastName}
                onChange={({ target }) => handleData('lastName', target.value)}
              />
            </td>
          </tr>
          <tr>
            <th className="required">Phone Number</th>
            <td>
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                value={info.phone}
                onChange={({ target }) => handleData('phone', target.value)}
              />
            </td>
          </tr>
          <tr>
            <th className="ms">Gender</th>
            <td>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={({ target }) => handleData('gender', target.value)}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio checked={info.gender === 'male'} />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio checked={info.gender === 'female'} />}
                  label="Female"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio checked={info.gender === 'other'} />}
                  label="Other"
                />
              </RadioGroup>
            </td>
          </tr>

          <tr>
            <th>Date of Birth</th>
            <td>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ textField: { fullWidth: true } }}
                  value={dayjs(info.birth)}
                  onChange={(e: Dayjs | null) => {
                    if (e) handleData('birth', e.format('YYYYMMDD'))
                  }}
                />
              </LocalizationProvider>
            </td>
          </tr>
          <tr>
            <th rowSpan={4} className="ms">
              Address
            </th>
            <td className="flex">
              <TextField
                label="Unit/Flat"
                variant="outlined"
                sx={{ width: '50%' }}
                value={info.unit ? info.unit : ''}
                onChange={({ target }) => handleData('unit', target.value)}
              />

              <TextField
                label="Number"
                variant="outlined"
                sx={{ width: '50%' }}
                value={info.number ? info.number : ''}
                onChange={({ target }) => handleData('number', target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                value={info.address}
                onChange={({ target }) => handleData('address', target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TextField
                label="City / Town or Suburb"
                variant="outlined"
                fullWidth
                value={info.city}
                onChange={({ target }) => handleData('city', target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={info.country}
                  label="Select Country"
                  onChange={({ target }) => handleData('country', target.value)}
                >
                  <MenuItem value="AU">Australia</MenuItem>
                  <MenuItem value="NZ">New Zealand</MenuItem>
                </Select>
              </FormControl>
            </td>
          </tr>
        </table>
      </div>
      <div className="bottom-area flex">
        <div className="btn-wrap">
          <div className="btn style01 outline" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div className="btn style01 em" onClick={() => notificationAxios()}>
            Save
          </div>
        </div>
      </div>
    </>
  )
}

export default PersonalInfo

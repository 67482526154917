import { useState, Dispatch, SetStateAction, MouseEvent } from 'react'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import axios from 'axios'
import Swal from 'sweetalert2'

// tools
import User from '../../tools/userInfo'

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>
}

interface Show {
  [key: string]: boolean
  current: boolean
  new: boolean
  confirm: boolean
}

interface Value {
  [key: string]: string
  current: string
  new: string
  confirm: string
}

const ChangePassword = ({ setOpen }: ModalContent) => {
  const { api, token } = User()
  const [showPassword, setShowPassword] = useState<Show>({
    current: false,
    new: false,
    confirm: false,
  })
  const [value, setValue] = useState<Value>({
    current: '',
    new: '',
    confirm: '',
  })

  const handleClickShowPassword = (key: string) =>
    setShowPassword(prev => ({ ...prev, [key]: !showPassword[key] }))

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) =>
    event.preventDefault()

  const changeAxios = () => {
    if (value.new === value.confirm) {
      axios
        .put(
          `${api}/auth/password`,
          {
            oldPassword: value.current,
            newPassword: value.new,
          },
          {
            headers: {
              'ngrok-skip-browser-warning': '69420',
              Authorization: 'bearer ' + token,
            },
          }
        )
        .then(() => {
          Swal.fire({
            title: 'Success',
            text: 'Your password has been successfully changed.',
            icon: 'success',
            allowOutsideClick: false,
            didClose: () => location.reload(),
          })
        })
        .catch(({ response }) =>
          Swal.fire({
            title: 'Error',
            text: response.data.message,
            icon: 'error',
            allowOutsideClick: false,
          })
        )
    } else {
      Swal.fire({
        title: 'Error',
        text: 'New password and Confirm new password does not match. \nPlease Check again',
        icon: 'error',
        allowOutsideClick: false,
      })
    }
  }

  return (
    <>
      <div className="tit-area flex">
        <h3 className="f24">Change Password</h3>
        <span
          className="btn close material-symbols-outlined"
          onClick={() => setOpen(false)}
        >
          close
        </span>
      </div>
      <div className="cont">
        <table className="form">
          <tr>
            <th>Current Password</th>
            <td>
              <FormControl
                sx={{
                  width: 'fit-content',
                  margin: '0 auto 8px',
                  display: 'block',
                }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Current Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword.current ? 'text' : 'password'}
                  onChange={({ target }) =>
                    setValue(prev => ({ ...prev, current: target.value }))
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword('current')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword.current ? (
                          <span className="material-symbols-outlined">
                            visibility
                          </span>
                        ) : (
                          <span className="material-symbols-outlined">
                            visibility_off
                          </span>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Current Password"
                />
              </FormControl>
            </td>
          </tr>
          <tr>
            <th>New Password</th>
            <td>
              <FormControl
                sx={{
                  width: 'fit-content',
                  margin: '0 auto 8px',
                  display: 'block',
                }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  New Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword.new ? 'text' : 'password'}
                  onChange={({ target }) =>
                    setValue(prev => ({ ...prev, new: target.value }))
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword('new')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword.new ? (
                          <span className="material-symbols-outlined">
                            visibility
                          </span>
                        ) : (
                          <span className="material-symbols-outlined">
                            visibility_off
                          </span>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                />
              </FormControl>
            </td>
          </tr>
          <tr>
            <th>Confirm Password</th>
            <td>
              <FormControl
                sx={{
                  width: 'fit-content',
                  margin: '0 auto',
                  display: 'block',
                }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm New Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword.confirm ? 'text' : 'password'}
                  onChange={({ target }) =>
                    setValue(prev => ({ ...prev, confirm: target.value }))
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword('confirm')}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword.confirm ? (
                          <span className="material-symbols-outlined">
                            visibility
                          </span>
                        ) : (
                          <span className="material-symbols-outlined">
                            visibility_off
                          </span>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm New Password"
                />
              </FormControl>
            </td>
          </tr>
        </table>
      </div>
      <div className="bottom-area flex">
        <div className="btn-wrap">
          <div className="btn style01 outline" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div className="btn style01 em" onClick={() => changeAxios()}>
            Change
          </div>
        </div>
      </div>
    </>
  )
}

export default ChangePassword

interface HowProps {
  type: string
}

const About = ({ type }: HowProps) => {
  if (type === 'power') {
    return (
      <div className="tab-cont">
        <h4 className="f24">All you need to know about Powerball</h4>
        <p className="f16">
          Powerball is a large national jackpot game with three draws a week and
          nine ways to win! For just $2, you could win up to the jackpot! Play
          today! ㅍ
          <br /> You have the same options for online Powerball purchases that
          you have in retail stores.
        </p>
        <h4 className="f24">Powerball Drawings</h4>
        <p className="f16">
          Powerball drawings are conducted Monday, Wednesday, and Saturday
          evenings at 9:59 PM CT by the Multi-State Lottery Association.
        </p>
        {/* <h4 className="f24">Power Play®</h4>
        <p className="f16">
          Play Power Play for a chance to increase your non-jackpot winnings,
          excluding the Match 5 prize, by 2, 3, 4, 5 or 10 times. The Match 5
          prize is increased to $2 million if Power Play is added, regardless of
          the Power Play number drawn.
        </p> */}
      </div>
    )
  } else {
    return (
      <div className="tab-cont">
        <h4 className="f24">All you need to know about Mega Millions</h4>
        <p className="f16">
          Mega Millions is a large multi-state lottery game with two draw days a
          week and nine ways to win! For just $2, you could win up to the
          jackpot! Play today! <br />
          You have the same options for online Mega Millions purchases that you
          have in retail stores.
        </p>
        <h4 className="f24">Mega Millions Drawings</h4>
        <p className="f16">
          Mega Millions drawings are conducted Tuesday and Friday evenings at
          10:00 PM CT in a studio in Atlanta, GA. Drawing results can be found
          on the Lottery Cluster website: www.lotterycluster.com
        </p>
        {/* <h4 className="f24">Megaplier®</h4>
        <p className="f16">
          Play Megaplier for a chance to increase your non-jackpot winnings by
          2, 3, 4, or 5 times. If you match any of your numbers with the winning
          numbers drawn (with the exception of the jackpot win), you will win
          that prize multiplied by the Megaplier number drawn.
        </p> */}
      </div>
    )
  }
}

export default About

import { useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { PieChart } from '@mui/x-charts/PieChart'
import axios from 'axios'

// type
import type { Pie } from '../../pages/stat'

// tools
import User from '../../tools/userInfo'

interface Combination {
  id: number
  value: number
  label: string
}

interface List {
  drawnum: number
  even: number
  numbers: string
  odd: number
  playdate: string
}

const Oddeven = ({ type, month }: Pie) => {
  const { api } = User()
  const [combination, setCombination] = useState<Combination[]>([
    { id: 1, value: 0, label: 'All Even' },
    {
      id: 2,
      value: 0,
      label: 'Single Odd and 5 Even',
    },
    {
      id: 3,
      value: 0,
      label: '2 Odd and 4 Even',
    },
    { id: 4, value: 0, label: '3 Odd and 3 Even' },
    {
      id: 5,
      value: 0,
      label: '2 Even and 4 Odd',
    },
    {
      id: 6,
      value: 0,
      label: 'Single Even and 5 Odd',
    },
    { id: 7, value: 0, label: 'All Odd' },
  ])
  const [list, setList] = useState<List[]>([])
  const [total, setTotal] = useState<number>(0)

  const dataAxios = () => {
    const Type = type === 'mega' ? 'mm' : 'pb'
    const today = new Date()
    const startDate = new Date(today)
    startDate.setMonth(today.getMonth() - Number(month))

    const toMonth =
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1
    const sMonth =
      startDate.getMonth() + 1 < 10
        ? `0${startDate.getMonth() + 1}`
        : startDate.getMonth() + 1

    const start = `${startDate.getFullYear()}-${sMonth}-${startDate.getDate()}`
    const end = `${today.getFullYear()}-${toMonth}-${today.getDate()}`

    axios
      .get(`${api}/number/oddeven?type=${Type}&sDate=${start}&eDate=${end}`, {
        headers: {
          'ngrok-skip-browser-warning': '69420',
        },
      })
      .then(({ data }) => {
        const copiedCombi = [...combination]

        for (let i = 0; i < data.data.combination.length; i++) {
          const item = copiedCombi[i]

          item.value = data.data.combination[i]

          copiedCombi[i] = { ...item }
        }

        setCombination(copiedCombi)
        setTotal(data.data.totalCount)
        setList(data.data.data)
      })
  }

  useEffect(() => dataAxios(), [month])

  const matches = useMediaQuery('(max-width:480px)')

  return (
    <div className="stat-result odd-even">
      <div className="item box style01">
        <h4 className="f24">Odd and Even Number Analysis</h4>
        <table className="style01">
          <tr>
            <th>Odd and Even Ratio</th>
            <th>Ratio Graph</th>
            <th>Frequency</th>
            <th>Winning Probability</th>
          </tr>

          {combination.map(item => (
            <tr key={item.id}>
              <td>{item.label}</td>
              <td>
                <div
                  className="bar"
                  style={{
                    width: `${((item.value / total) * 100).toFixed(1)}%`,
                  }}
                />
              </td>
              <td>{item.value} times</td>
              <td>{((item.value / total) * 100).toFixed(1)}%</td>
            </tr>
          ))}
        </table>
      </div>

      <div className="item box style01">
        <h4 className="f24">Pie Chart of Odd and Even Number Combination</h4>

        <PieChart
          margin={
            matches
              ? { top: 0, bottom: 150, left: 0, right: 0 }
              : { top: 0, bottom: 0, left: 0, right: 250 }
          }
          series={[
            {
              data: combination,
            },
          ]}
          height={350}
          slotProps={
            matches
              ? {
                  legend: {
                    position: { vertical: 'bottom', horizontal: 'left' },
                    padding: 0,
                    itemMarkWidth: 20,
                    itemMarkHeight: 2,
                    markGap: 3,
                    itemGap: 1,
                  },
                }
              : {
                  legend: {
                    position: { vertical: 'middle', horizontal: 'right' },
                    padding: 0,
                    itemMarkWidth: 20,
                    itemMarkHeight: 2,
                    markGap: 3,
                    itemGap: 2,
                  },
                }
          }
        />
      </div>

      <div className="item box style01">
        <h4 className="f24">Odd and Even Number Analysis Table</h4>
        <div className="table-wrap">
          <table className="style01">
            <tr>
              <th>Draw Number</th>
              <th>Winning Numbers</th>
              <th>0:6</th>
              <th>1:5</th>
              <th>2:4</th>
              <th>3:3</th>
              <th>4:2</th>
              <th>5:1</th>
              <th>6:0</th>
            </tr>

            {list.map(item => {
              const ball = item.numbers.split(',')

              return (
                <tr key={item.drawnum}>
                  <td>#{item.drawnum}</td>
                  <td>
                    <div className="balls sm">
                      {ball.map((num, index) =>
                        index === ball.length - 1 ? (
                          <div
                            key={`${item.drawnum}_${num}_${index}`}
                            className={`ball ${type}`}
                          >
                            {num}
                          </div>
                        ) : (
                          <div
                            key={`${item.drawnum}_${num}_${index}`}
                            className="ball"
                          >
                            {num}
                          </div>
                        )
                      )}
                    </div>
                  </td>

                  {combination.map((com, index) =>
                    index === item.odd ? (
                      <td key={com.id}>
                        <span className="material-symbols-outlined">check</span>
                      </td>
                    ) : (
                      <td key={com.id} />
                    )
                  )}
                </tr>
              )
            })}
          </table>
        </div>
      </div>
    </div>
  )
}

export default Oddeven

import { useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import Lottie from 'lottie-react'

// tools
import User from '../tools/userInfo'
import { update } from '../tools/zustandUpdate'

// img
import GoogleLoading from './../img/common/google-loading-lottie.json'

const Google = () => {
  const { api, setToken, setTotalInfo } = User()
  const query = location.search.split('?')[1].split('&')
  const state = query.filter(x => x.includes('state='))[0].split('=')[1]
  const code = query.filter(x => x.includes('code='))[0].split('=')[1]

  const googleAxios = () => {
    axios
      .post(
        `${api}/login/google`,
        {
          code,
          redirect: location.origin,
        },
        { headers: { 'ngrok-skip-browser-warning': '69420' } }
      )
      .then(({ data }) => {
        if (data.message === 'success') {
          setToken(data.data.token)

          Swal.fire({
            title: 'Login Success',
            icon: 'success',
            allowOutsideClick: false,
            didClose: () => {
              update(api, data.data.token, setTotalInfo)

              const arrival = decodeURIComponent(state)

              if (arrival === '/welcome') {
                location.replace('/')
              } else {
                location.replace(arrival)
              }
            },
          })
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.message,
          icon: 'error',
          allowOutsideClick: false,
        })
      })
  }

  const googleCheckAxios = () => {
    axios
      .post(
        `${api}/registration/check`,
        { provider: 'google', code, redirect: location.origin },
        { headers: { 'ngrok-skip-browser-warning': '69420' } }
      )
      .then(({ data }) => {
        const socialInfo = {
          email: data.data.email,
          provider: 'google',
        }

        sessionStorage.setItem('social', JSON.stringify(socialInfo))

        location.replace(decodeURIComponent(state))
      })
      .catch(({ response }) =>
        Swal.fire({
          title: response.data.message,
          icon: 'error',
          allowOutsideClick: false,
          didClose: () => location.replace('/signup'),
        })
      )
  }

  useEffect(() => {
    if (state.includes('signup')) {
      googleCheckAxios()
    } else {
      googleAxios()
    }
  }, [])

  return (
    <div className="google-loading">
      <div className="lottie-wrap">
        <Lottie animationData={GoogleLoading} className="google-loading-img" />
      </div>
    </div>
  )
}

export default Google

import axios from 'axios'

// tools
import type { Info } from './userInfo'

export const update = (
  api: string,
  token: string,
  setTotalInfo: (info: Info) => void
) => {
  axios
    .get(`${api}/auth/me`, {
      headers: {
        'ngrok-skip-browser-warning': '69420',
        Authorization: 'bearer ' + token,
      },
    })
    .then(({ data }) => {
      if (data.message) {
        setTotalInfo(data.data)
      }
    })
    .catch(response => {})
}

export const reset = (setTotalInfo: (info: Info) => void) => {
  setTotalInfo({
    address: '',
    birth: '',
    cash: 0,
    city: '',
    country: '',
    createdAt: '',
    email: '',
    firstName: '',
    gender: '',
    lastName: '',
    level: 0,
    marketing: '',
    notify: '',
    number: '',
    phone: '',
    unit: '',
    provider: '',
    prize: 0,
    totalPrize: 0,
    weeklySpendLimit: 0,
    remaining: 0,
  })
}

import { Dispatch, SetStateAction, useState } from 'react'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import axios from 'axios'
import Swal from 'sweetalert2'

//tools
import User from '../../tools/userInfo'

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>
}

const Notification = ({ setOpen }: ModalContent) => {
  const { api, info, token } = User()

  const [data, setData] = useState({
    sms: info.notify === 'Y',
    app: info.marketing === 'Y',
  })

  const notificationAxios = () => {
    axios
      .put(
        `${api}/auth/update`,
        {
          notify: data.sms ? 'Y' : 'N',
          marketing: data.app ? 'Y' : 'N',
        },
        {
          headers: {
            'ngrok-skip-browser-warning': '69420',
            Authorization: 'bearer ' + token,
          },
        }
      )
      .then(({ data }) => {
        if (data.message) {
          Swal.fire({
            title: 'Update Completed',
            icon: 'success',
            allowOutsideClick: false,
            didClose: () => location.reload(),
          })
        }
      })
      .catch(({ response }) => {})
  }

  return (
    <div className="Notification">
      <div className="tit-area flex">
        <h3 className="f24">Notification Settings</h3>
        <span
          className="btn close material-symbols-outlined"
          onClick={() => setOpen(false)}
        >
          close
        </span>
      </div>
      <div className="cont">
        <p className="exp">
          Please note: You cannot unsubscribe from account related emails e.g.
          Order confirmation, Win notification, etc
        </p>
        <table className="form">
          <tr>
            <td>
              <RadioGroup sx={{ width: 'fit-content', margin: '0 auto' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.sms}
                      onChange={({ target }) => {
                        setData(prev => ({
                          ...prev,
                          sms: target.checked,
                        }))
                      }}
                    />
                  }
                  label="SMS"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={({ target }) => {
                        setData(prev => ({
                          ...prev,
                          app: target.checked,
                        }))
                      }}
                      checked={data.app}
                    />
                  }
                  label="App Push"
                />
              </RadioGroup>
            </td>
          </tr>
        </table>
      </div>
      <div className="bottom-area flex">
        <div className="btn-wrap">
          <div className="btn style01 outline" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div className="btn style01 em" onClick={() => notificationAxios()}>
            Set
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notification

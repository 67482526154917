import { Dispatch, SetStateAction, useEffect } from 'react'
import Lottie from 'lottie-react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

// img
import Confetti from './../img/common/confetti.json'

// tool
import User from '../tools/userInfo'

interface Info {
  email: string
  password: string
  gender: string
  firstName: string
  lastName: string
  phone: string
  birth: string
  unit: string
  number: string
  address: string
  city: string
  country: string
  notify: string
  marketing: string
}

interface Props {
  info: Info
  setOpen: Dispatch<SetStateAction<boolean>>
}

const SignUpComplete = ({ info, setOpen }: Props) => {
  const { token } = User()

  useEffect(() => {
    if (token) {
      location.replace('/')
    }
  }, [])

  return (
    <div className="signup complete">
      <div className="inner">
        <div className="tit-area">
          <Lottie animationData={Confetti} className="confetti" loop={false} />
          <span className="material-symbols-outlined f60">check_circle</span>
          <h3 className="tit f40">Success</h3>
          <p className="f16">
            Congratulations! <br />
            Your Account has been created.
          </p>
        </div>
        <div className="cont">
          <p className="f18 tit">Your Informations</p>
          <table>
            <tr>
              <th>Email</th>
              <td>{info.email}</td>
            </tr>
            <tr>
              <th>Gender</th>
              <td>{info.gender}</td>
            </tr>
            <tr>
              <th>First Name</th>
              <td>{info.firstName}</td>
            </tr>
            <tr>
              <th>Last Name</th>
              <td>{info.lastName}</td>
            </tr>
            <tr>
              <th>Phone Number</th>
              <td>{info.phone}</td>
            </tr>
            <tr>
              <th>Date of Birth</th>
              <td>{info.birth}</td>
            </tr>
            <tr>
              <th>Address</th>
              <td>
                {info.address}, {info.city}
              </td>
            </tr>
            <tr>
              <th>Notifications</th>
              <td>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={info.notify === 'Y'} />}
                    label="SNS notifications"
                    disabled
                  />
                  <FormControlLabel
                    control={<Checkbox checked={info.marketing === 'Y'} />}
                    label="App Push"
                    disabled
                  />
                </FormGroup>
              </td>
            </tr>
          </table>
        </div>
        <div className="btn-area flex">
          <a href="/" className="btn style01 home">
            <span className="material-symbols-outlined">chevron_left</span>
            Home
          </a>
          <a href="#" className="btn style01" onClick={() => setOpen(true)}>
            Log In
            <span className="material-symbols-outlined">chevron_right</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default SignUpComplete

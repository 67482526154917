import { useState, useEffect, useRef } from "react";
import axios from "axios";

// type
import type { Pie } from "../../pages/stat";

// img
import MegaTicket from "../../img/sub/mega.png";
import PowerTicket from "../../img/sub/power.png";

// tools
import User from "../../tools/userInfo";

interface List {
  drawnum: number;
  numbers: string;
  playdate: string;
}

interface Ticket {
  type: string;
  item: List;
}

const Pattern = ({ type, month }: Pie) => {
  const { api } = User();

  const [list, setList] = useState<List[]>([]);

  const dataAxios = () => {
    const Type = type === "mega" ? "mm" : "pb";
    const today = new Date();
    const startDate = new Date(today);
    startDate.setMonth(today.getMonth() - Number(month));

    const toMonth =
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1;
    const sMonth =
      startDate.getMonth() + 1 < 10
        ? `0${startDate.getMonth() + 1}`
        : startDate.getMonth() + 1;

    const start = `${startDate.getFullYear()}-${sMonth}-${startDate.getDate()}`;
    const end = `${today.getFullYear()}-${toMonth}-${today.getDate()}`;

    axios
      .get(`${api}/number?type=${Type}&sDate=${start}&eDate=${end}`, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
        },
      })
      .then(({ data }) => {
        setList(data.data.data);
      });
  };

  useEffect(() => {
    dataAxios();
  }, [month]);

  return (
    <div className="stat-result pattern">
      {list.map((item) => (
        <Ticket key={item.drawnum} type={type} item={item} />
      ))}
    </div>
  );
};

export default Pattern;

const Ticket = ({ type, item }: Ticket) => {
  const ref = useRef<HTMLCanvasElement>(null);

  const patternMaker = () => {
    const canvas = ref.current;

    if (canvas) {
      canvas.width = 284;
      canvas.height = 352;
      const context = canvas.getContext("2d") as CanvasRenderingContext2D;

      var img = new Image();
      img.src = type === "mega" ? MegaTicket : PowerTicket;

      img.onload = function () {
        context.drawImage(img, 0, 0, 284, 352);
        const markingW = 13;
        const markingH = 19;

        var xArray = [17, 43, 70, 96, 122, 149, 175, 201, 228, 254]; // 배경 이미지에서의 각 마킹 공간 X 축 좌표
        var yArray = [16, 47, 78, 109, 140, 171, 202]; // 배경 이미지에서의 각 마킹 공간 Y 축 좌표
        var syArray = [254, 285, 316];
        var ballNum = item.numbers.split(",").map((el) => Number(el));
        var pointX = [],
          pointY = [];

        if (type == "mega") {
          context.fillStyle = "rgba(248,196,49, 0.5)";
        } else {
          context.fillStyle = "rgba(241, 77, 93, 0.5)";
        }

        for (let i = 0; i < 6; i++) {
          var row = Math.floor((ballNum[i] - 1) / 10); // 마킹이 칠해질 줄을 계산
          var yPos = i === 5 ? syArray[row] : yArray[row]; // 일반 볼인지 스폐셜 볼인지에 따른 Y 값 좌표 설정

          context.fillRect(
            xArray[(ballNum[i] - 1) % 10],
            yPos,
            markingW,
            markingH
          ); // 위 설정된 좌표값으로 마킹지에 마킹

          // 다음 마킹영역까지 라인을 긋기 위한 데이터 설정
          var pointTmpX = xArray[(ballNum[i] - 1) % 10] + markingW / 2;
          var pointTmpY = yPos + markingH / 2;
          pointX.push(pointTmpX);
          pointY.push(pointTmpY);
        }

        for (let i = 0; i < 5; i++) {
          context.beginPath();
          context.moveTo(pointX[i], pointY[i]); // 마킹시 설정된 좌표값으로 시작 라인 설정
          context.lineTo(pointX[i + 1], pointY[i + 1]); // 다음 마킹 영역으로 라인을 그어줌
          context.lineWidth = 4; // 라인의 높이 폭 설정 (PX 단위)
          // 라인 색상 지정
          if (type == "mega") {
            context.strokeStyle = "rgba(248,196,49, 0.9)";
          } else {
            context.strokeStyle = "rgba(241, 77, 93, 0.9)";
          }
          // 라인의 형태 설정
          context.lineCap = "round";
          context.lineJoin = "round";
          context.stroke();
        }
      };
    }
  };

  useEffect(() => {
    patternMaker();
  }, []);

  return (
    <div className="item">
      <p className="f18">Draw: #{item.drawnum}</p>
      <canvas ref={ref} />
    </div>
  );
};

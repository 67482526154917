import NewsImg01 from "../../img/main/main-news01.png";
import NewsImg02 from "../../img/main/main-news02.png";

const News = () => {
  return (
    <section className="sec-news">
      <div className="inner">
        <h3 className="f24">Latest News, Real Stories and News</h3>
        <div className="news-items">
          <div className="first">
            <div className="img-area">
              <img src={NewsImg01} alt="" />
            </div>
            <div className="txt-area">
              <h4 className="f18">
                Powerball winning numbers for March 13, 2024 drawing: Jackpot up
                to $559 million
              </h4>
              <p className="f16 date">March 13, 2024</p>
              <p className="f14 txt">
                The Powerball jackpot for Wednesday's drawing is now worth an
                estimated $559 million after there was no jackpot winner in
                Monday's drawing. The numbers were rolled and selected just
                after 11 p.m. EST on Wednesday, and we have the results below.
                If there is a winner after Wednesday's drawing and they choose
                the cash option, they could take home $273.3 million, according
                to the lottery. Here are the winning numbers for the March 13
                drawing:
              </p>
            </div>
          </div>

          <div className="item">
            <div className="img-area">
              <img src={NewsImg02} alt="" />
            </div>
            <div className="txt-area">
              <h4 className="f18">
                Powerball winning numbers for March 13, 2024 drawing: Jackpot up
                to $559 million
              </h4>
            </div>
          </div>
          <div className="item">
            <div className="img-area">
              <img src={NewsImg02} alt="" />
            </div>
            <div className="txt-area">
              <h4 className="f18">
                Powerball winning numbers for March 13, 2024 drawing: Jackpot up
                to $559 million
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;

import { useState } from 'react'
import axios from 'axios'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Swal from 'sweetalert2'

// components
import { CustModal } from '../common/commonElements'

//tools
import User from '../../tools/userInfo'
import { reset } from '../../tools/zustandUpdate'

const Delete = () => {
  const { api, token, info, setToken, setTotalInfo } = User()
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [password, setPassword] = useState('')

  const deleteAxios = () => {
    axios
      .delete(`${api}/auth/me`, {
        data: {
          password,
          message: '',
        },
        headers: {
          'ngrok-skip-browser-warning': '69420',
          Authorization: 'bearer ' + token,
        },
      })
      .then(({ data }) => {
        if (data.message) {
          Swal.fire({
            title: 'Sucxess',
            text: 'Your account is completely deleted',
            icon: 'success',
            allowOutsideClick: false,
            didClose: () => {
              reset(setTotalInfo)
              setToken('')
              location.href = '/'
            },
          })
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: 'Error',
          text: response.data.message,
          icon: 'error',
          allowOutsideClick: false,
        })
      })
  }

  const deleteFunction = () => {
    const social = info.provider !== 'cluster'

    if (social) {
      Swal.fire({
        title: 'Do you want to close your account?',
        text: 'This action cannot be recovered',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(result => {
        if (result.isConfirmed) {
          deleteAxios()
        }
      })
    } else {
      setOpen(true)
    }
  }

  return (
    <>
      <div className="btn delete-id" onClick={() => deleteFunction()}>
        Delete ID
      </div>

      <CustModal open={open} setOpen={setOpen} onClose={() => setOpen(false)}>
        <div className="delete-id">
          <div className="tit-area flex">
            <h3 className="f24">Delete ID</h3>
            <span
              className="btn close material-symbols-outlined"
              onClick={() => setOpen(false)}
            >
              close
            </span>
          </div>

          {/* step01 - Check Password */}
          <div className="cont">
            <h4 className="f24">Please Check Your Password</h4>
            <FormControl
              sx={{ width: 'fit-content', display: 'block', margin: '0 auto' }}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={show ? 'text' : 'password'}
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShow(prev => !prev)}
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      {show ? (
                        <span className="material-symbols-outlined">
                          visibility
                        </span>
                      ) : (
                        <span className="material-symbols-outlined">
                          visibility_off
                        </span>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </div>

          {/* step02 - Check Reason */}
          {/* <div className="cont">
            <h4 className="f24">Reason for deleting your ID</h4>
            <p className="f16 exp">
              Warning! Your account will be closed. Your winnings will be paid
              out to you, however we are not permitted to pay out any unspent
              deposited funds. That portion of your balance will be paid to the
              Government Regulator for their determination, unless you spend the
              funds prior to closing your account.
            </p>
            <FormControl>
              <RadioGroup name="radio-buttons-group">
                <FormControlLabel
                  value="I'm getting too many emails"
                  control={<Radio />}
                  label="I'm getting too many emails"
                />
                <FormControlLabel
                  value="I would like to reduce my spending"
                  control={<Radio />}
                  label="I would like to reduce my spending"
                />
                <FormControlLabel
                  value="I'm no longer interested in lottery"
                  control={<Radio />}
                  label="I'm no longer interested in lottery"
                />
                <FormControlLabel
                  value="gambling problem"
                  control={<Radio />}
                  label="I have a gambling problem"
                />
                <FormControlLabel
                  value="My family doesn't want me to play"
                  control={<Radio />}
                  label="My family doesn't want me to play"
                />
                <FormControlLabel
                  value="inconvenient"
                  control={<Radio />}
                  label="It is inconvenient for me to use this service"
                />
              </RadioGroup>
            </FormControl>
          </div> */}
          <div className="bottom-area flex">
            <div className="btn-wrap">
              <div
                className="btn style01 outline"
                onClick={() => setOpen(false)}
              >
                Cancel
              </div>
              <div className="btn style01" onClick={() => deleteAxios()}>
                Delete
              </div>
            </div>
          </div>
        </div>
      </CustModal>
    </>
  )
}

export default Delete

import { useState } from 'react'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'
import axios from 'axios'
import Swal from 'sweetalert2'

// components
import Wrapper from '../components/common/wrapper'

// tools
import User from '../tools/userInfo'

const EmailUs = () => {
  const [info, setInfo] = useState({
    name: '',
    email: '',
    topic: '',
    content: '',
  })
  const { api } = User()

  const handleData = (key: string, value: string) => {
    setInfo(prev => ({ ...prev, [key]: value }))
  }

  const matches = useMediaQuery('(max-width:480px)')

  const emailAxios = () => {
    const values = Object.values(info)
    const keys = Object.keys(info)

    const empty = values.findIndex(x => !Boolean(x))

    if (empty === -1) {
      axios
        .post(`${api}/support/contact/email`, info, {
          headers: {
            'ngrok-skip-browser-warning': '69420',
          },
        })
        .then(_ => {
          Swal.fire({
            title: 'Success',
            icon: 'success',
            text: 'Email has been sent successfully. We will response as soon as possible.',
            allowOutsideClick: false,
          })
        })
        .catch(({ response }) => {
          Swal.fire({
            title: 'Error',
            text: response.data.message,
            icon: 'error',
            allowOutsideClick: false,
            didClose: () => window.location.reload(),
          })
        })
    } else {
      Swal.fire({
        title: 'Error',
        text: `You haven't filled out a required field: ${keys[empty]}`,
        icon: 'error',
        allowOutsideClick: false,
      })
    }
  }

  return (
    <Wrapper>
      <div className="sub email-us">
        <div className="inner">
          <div className="ico">
            <span className="material-symbols-outlined f40">mail</span>
          </div>
          <div className="bg-cont-wrap">
            <p className="bg">Contact Us</p>
            <div className="email-us">
              <div className="tit-area">
                <h3 className="f40">Email Us</h3>
                <p className="f18">
                  Our team of lottery experts is prepared to provide a quick and
                  thorough response to all your questions and concerns via
                  email.
                </p>
              </div>
              <div className="cont">
                <table className="form">
                  <colgroup>
                    <col width="15%" />
                    <col width="85%" />
                  </colgroup>
                  <tr>
                    <th className="required">Name</th>
                    <td>
                      <TextField
                        label="Name"
                        sx={{ width: '50%' }}
                        value={info.name}
                        onChange={({ target }) =>
                          handleData('name', target.value)
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="required">Email</th>
                    <td>
                      <TextField
                        label="Email"
                        sx={{ width: '50%' }}
                        value={info.email}
                        onChange={({ target }) =>
                          handleData('email', target.value)
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="required">Topic</th>
                    <td>
                      <TextField
                        label="Topic"
                        sx={matches ? { width: '100%' } : { width: '50%' }}
                        value={info.topic}
                        onChange={({ target }) =>
                          handleData('topic', target.value)
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="required">Content</th>
                    <td>
                      <div className="cust-textarea">
                        <textarea
                          value={info.content}
                          onChange={({ target }) =>
                            handleData('content', target.value)
                          }
                        />
                      </div>
                    </td>
                  </tr>
                </table>
                <div className="btn-wrap">
                  {/* <div className="btn style01 outline">Cancel</div> */}
                  <div className="btn style01 em" onClick={() => emailAxios()}>
                    Send
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default EmailUs

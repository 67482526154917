// common
import { motion } from 'framer-motion'

// style
import { PageEffect } from '../../styles/pageEffect'

type WrapperProps = {
  children: React.ReactNode
}

const Wrapper = ({ children }: WrapperProps) => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      transition={{ duration: 0.3 }}
      variants={PageEffect}
    >
      {children}
    </motion.div>
  )
}

export default Wrapper

export const Menu = [
  {
    id: 1,
    text: 'Lottery Cluster',
    subItems: [{ id: 11, text: 'About Us', link: '/about' }],
  },
  {
    id: 2,
    text: 'Service',
    subItems: [
      { id: 21, text: 'Mega Millions', link: '/mega' },
      { id: 22, text: 'Powerball', link: '/power' },
    ],
  },
  {
    id: 3,
    text: 'Information',
    subItems: [
      { id: 31, text: 'Privacy', link: '/privacy' },
      { id: 32, text: 'Terms and Conditions', link: '/terms' },
    ],
  },
  {
    id: 4,
    text: 'Help Center',
    subItems: [{ id: 41, text: 'FAQ', link: '/faq' }],
  },
]

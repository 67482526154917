import { useState, useEffect } from 'react'

// components
import Wrapper from '../components/common/wrapper'
import LastWinningResult from '../components/sub/lastWinningResult'

const Result = () => {
  const [menu, setMenu] = useState('')

  useEffect(() => {
    const search = location.search
    const split = search.replace('?', '').split('&')
    const tab = split[0].split('=')[1]

    if (tab) {
      setMenu(tab)
    } else {
      setMenu('mega')
    }
  }, [])

  return (
    <Wrapper>
      <div className="sub result">
        <div className="top-info-area inner">
          <div className="txt-area">
            <p className="f40">Result</p>
            <p className="f18 txt">
              Lottery results are updated around the clock!
            </p>
          </div>
        </div>
        <div className="tab-area">
          <ul className="style01">
            <li
              className={menu === 'mega' ? 'current' : ''}
              onClick={() => (location.href = '/result?tab=mega')}
            >
              Mega Millions
            </li>
            <li
              className={menu === 'power' ? 'current' : ''}
              onClick={() => (location.href = '/result?tab=power')}
            >
              Powerball
            </li>
          </ul>
        </div>
        <LastWinningResult type={menu} />
      </div>
    </Wrapper>
  )
}

export default Result

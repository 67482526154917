import { Link } from 'react-router-dom'

// img
import ErrorImg from '../img/common/error-img.svg'

const ErrorPage = () => {
  return (
    <div className="error">
      <div className="txt-area inner">
        <p className="f404">404</p>
        <p className="f40">Oops! Something went wrong.</p>
        <p className="f18">
          The page you're searching for cannot be found. But here are some
          <br />
          useful links instead:
        </p>

        <div className="btn-wrap">
          <Link to="/" className="btn txt">
            Home
          </Link>
          <Link to="/email" className="btn txt">
            Contact Us
          </Link>
        </div>
      </div>
      <img src={ErrorImg} alt="404 Error" />
    </div>
  )
}

export default ErrorPage

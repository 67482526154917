export const SliderSettings = {
  vertical: true,
  pauseOnHover: true,
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  initialSlide: 0,
  draggable: false,
  className: "slider",
};

export const EventSliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplaySpeed: 5000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  draggable: true,
  pauseOnHover: true,
  arrows: false,
  className: "event-slider",
  // nextArrow: <NextArr />,
  // prevArrow: <PrevArr />,
};

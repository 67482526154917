import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import Skeleton from '@mui/material/Skeleton'

//  img
import MegaLogo from '../../img/common/megamillions-logo.png'
import PowerLogo from '../../img/common/powerball-logo.png'

// tools
import User from '../../tools/userInfo'

// components
import NoData from '../common/noData'

interface List {
  auDrawTime: string
  auOrderTime: string
  created_at: string
  drawnum: number
  game1: string | null
  game2: string | null
  game3: string | null
  game4: string | null
  game5: string | null
  game_cnt: number
  game_type: string
  idx: number
  prize_usd: number
  scanPath: null | string
  scan_status: string
  status: string
  winCount: null | number | string
  win_rank: null
  win_status: string
  winningBalls: string
}

const Draws = () => {
  const { api, token, info } = User()
  const ref = useRef<Slider | null>(null)
  const [toggleDateLists, setToggleDateLists] = useState(false)

  const [period, setPeriod] = useState<string[]>([])
  const [selected, setSelected] = useState('')
  const [list, setList] = useState<List[]>([])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: period.length - 1,
    draggable: false,
    pauseOnHover: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: 'slider',
  }

  function PrevArrow(props: any) {
    const clickFunc = () => {
      const { currentSlide } = props

      if (currentSlide !== 0) {
        const copy = [...period]
        ref.current?.slickPrev()
        const current = copy.reverse()[currentSlide - 1]
        setSelected(current)
      }

      setToggleDateLists(false)
    }

    return (
      <div className="btn arr prev" onClick={() => clickFunc()}>
        <span className="material-symbols-outlined">chevron_left</span>
      </div>
    )
  }

  function NextArrow(props: any) {
    const clickFunc = () => {
      const { currentSlide, slideCount } = props

      if (currentSlide + 1 !== slideCount) {
        const copy = [...period]
        ref.current?.slickNext()
        const current = copy.reverse()[currentSlide + 1]
        setSelected(current)
      }

      setToggleDateLists(false)
    }
    return (
      <div className="btn arr next" onClick={() => clickFunc()}>
        <span className="material-symbols-outlined">chevron_right</span>
      </div>
    )
  }

  useEffect(() => {
    const created = info.createdAt.split(' ')[0]
    const [createdYear, createdMonth] = created.split('-')
    const createdValue = Number(createdYear) * 12 + Number(createdMonth)

    const today = new Date()
    const currentYear = today.getFullYear()
    const currentMonth = today.getMonth() + 1
    const currentValue = currentYear * 12 + currentMonth

    for (let i = currentValue; i > createdValue - 1; i--) {
      const y = Math.floor(i / 12)
      const m = i - y * 12

      const period = m === 0 ? `${y - 1}-12` : `${y}-${m < 10 ? `0${m}` : m}`

      setPeriod(prev => [...prev, period])

      if (i === currentValue) {
        setSelected(period)
      }
    }
  }, [])

  const specificAxios = () => {
    axios
      .post(
        `${api}/order/history`,
        {
          type: '',
          period: selected,
        },
        {
          headers: {
            'ngrok-skip-browser-warning': '69420',
            Authorization: 'bearer ' + token,
          },
        }
      )
      .then(({ data }) => {
        setList(data.data)
      })
  }

  useEffect(() => {
    if (selected) specificAxios()
  }, [selected])

  const MonthConverter = (month: string) => {
    const num = Number(month)

    switch (num) {
      case 1:
        return 'Jan'
        break

      case 2:
        return 'Feb'
        break

      case 3:
        return 'Mar'
        break

      case 4:
        return 'Apr'
        break

      case 5:
        return 'May'
        break

      case 6:
        return 'Jun'
        break

      case 7:
        return 'Jul'
        break

      case 8:
        return 'Aug'
        break

      case 9:
        return 'Sep'
        break

      case 10:
        return 'Oct'
        break

      case 11:
        return 'Nov'
        break

      case 12:
        return 'Dec'
        break
    }
  }

  return (
    <div className="draws">
      <div className="date-slide-wrap box style01">
        {period.length > 0 && (
          <div className="tit-area">
            <Slider ref={ref} {...settings}>
              {[...period].reverse().map(item => (
                <div key={item} className="date-wrap">
                  <p
                    className="f18 date"
                    onClick={() => setToggleDateLists(prev => !prev)}
                  >
                    {MonthConverter(selected.split('-')[1])}&nbsp;
                    {selected.split('-')[0]}
                    <span className="material-symbols-outlined">
                      keyboard_arrow_down
                    </span>
                  </p>
                  {toggleDateLists && (
                    <ul className="date-lists">
                      {[...period]
                        .filter(x => x !== selected)
                        .map(time => {
                          const [y, m] = time.split('-')

                          return (
                            <li
                              key={time}
                              onClick={() => {
                                const reverse = [...period].reverse()
                                const current = reverse.findIndex(
                                  target => target === time
                                )
                                setSelected(time)
                                setToggleDateLists(false)
                                ref.current?.slickGoTo(current, true)
                              }}
                            >
                              {MonthConverter(m)} {y}
                            </li>
                          )
                        })}
                    </ul>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        )}
        <div className="cont">
          <div className="draws-lists">
            {list.length > 0 ? (
              list.map(item => <Item key={item.idx} data={item} />)
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Draws

const Item = ({ data }: { data: List }) => {
  const { api } = User()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState({
    numbers: [],
    prize: 0,
  })
  const game = data.game_type === 'MM' ? 'mega' : 'power'
  const fullName = data.game_type === 'MM' ? 'Mega Millions' : 'PowerBall'
  const myNumbers = [data.game1, data.game2, data.game3, data.game4, data.game5]
  const white: string[] = [
    result.numbers[0],
    result.numbers[1],
    result.numbers[2],
    result.numbers[3],
    result.numbers[4],
  ]

  useEffect(() => {
    axios
      .get(`${api}/prize/${data.game_type.toLowerCase()}/${data.drawnum}`, {
        headers: {
          'ngrok-skip-browser-warning': '69420',
        },
      })
      .then(({ data }) => {
        setResult({
          numbers: data.data.winningNumber.split(','),
          prize: parseInt(data.data.prize),
        })

        setLoading(false)
      })
      .catch(({ response }) => {})
  }, [])

  const placeConverter = (place: number) => {
    const num = Number(place)

    if (num) {
      switch (num) {
        case 1:
          return '1st'
          break

        case 2:
          return '2nd'
          break

        case 3:
          return '3rd'
          break

        default:
          return `${num}th`
      }
    } else {
      return ''
    }
  }

  const RankText = (ranks: string | null) => {
    let rankText = ''
    if (ranks) {
      const rankArray = ranks
        .split(',')
        .map(item => Number(item))
        .sort()

      for (let i = 0; i < rankArray.length; i++) {
        const result = placeConverter(rankArray[i])

        if (result) {
          if (rankText) {
            rankText = rankText + '/' + result
          } else {
            rankText = result
          }
        }
      }
    }

    return rankText
  }

  const amountMaker = (amount: number) => {
    const inmil = Number((amount / 1000000).toFixed(1))
    let final = 'To Be Determined'

    if (inmil > 1000) {
      final = `USD ${(inmil / 1000).toFixed(1)} Billions*`
    } else if (inmil > 0) {
      final = `USD ${String(inmil)} Millions*`
    }

    return final
  }

  const imageSource = () => {
    location.href = `/ticket?code=${data.idx}`
  }

  return (
    <div className="item">
      <div className="summary">
        <img
          src={game == 'mega' ? MegaLogo : PowerLogo}
          alt="Lotto Games"
          className="game-logo"
        />
        <div className="prize-wrap">
          <p className={`f24 ${game}`}>{fullName}</p>
          <p className="f40">
            {loading ? (
              <Skeleton variant="rounded" width={300} height={50} />
            ) : (
              amountMaker(result.prize)
            )}
          </p>
        </div>
        <div className="result-scan-wrap">
          <p className="f18">
            {data.win_status === 'R'
              ? 'Draw Pending'
              : data.win_status === 'N'
              ? 'No Win'
              : `${RankText(data.win_rank)} place ($${data.prize_usd})`}
          </p>

          <p className="btn txt" onClick={() => imageSource()}>
            View matching ticket
          </p>
        </div>
        <div
          className="btn style01"
          onClick={() => (location.href = `/${game}`)}
        >
          Play Again
        </div>
      </div>

      {!open && (
        <div className="btn txt toggle" onClick={() => setOpen(prev => !prev)}>
          See draw details
          <span className="material-symbols-outlined f16">expand_more</span>
        </div>
      )}

      {/* {!open && data.scan_status === 'R' && (
        <div className="btn txt toggle" onClick={() => setOpen(prev => !prev)}>
          See draw details
          <span className="material-symbols-outlined f16">expand_more</span>
        </div>
      )} */}

      {open && (
        <div className="details">
          {result.numbers.length > 3 && (
            <div className="latest-winning-numbers">
              <p className="f24">Winning Numbers</p>
              <div className="balls">
                {result.numbers.map((item, index) =>
                  index === 5 ? (
                    <div
                      key={`${data.drawnum}_${item}_${index}`}
                      className={`ball ${game}`}
                    >
                      {item}
                    </div>
                  ) : (
                    <div
                      key={`${data.drawnum}_${item}_${index}`}
                      className="ball"
                    >
                      {item}
                    </div>
                  )
                )}
              </div>
              {/* <p className="f18">Multiplier 3x</p> */}
            </div>
          )}

          <div className="ball-info-wrap flex">
            <div className="balls-list">
              {myNumbers.map((num, index) =>
                num ? (
                  <div className="balls md" key={`${index}_${num}`}>
                    <p className="f18">
                      {index + 1 < 10 ? `0${index + 1}` : index + 1}
                    </p>
                    {num?.split(',').map((item, i) =>
                      i === 5 ? (
                        <div
                          key={`${data.drawnum}_${index}_${item}}_${i}`}
                          className={`ball ${game} ${
                            result.numbers[5] == item ? 'matching' : ''
                          }`}
                        >
                          {item}
                        </div>
                      ) : (
                        <div
                          key={`${data.drawnum}_${index}_${item}}_${i}`}
                          className={`ball ${
                            white.includes(item) ? 'matching' : ''
                          } `}
                        >
                          {item}
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <></>
                )
              )}
            </div>
            <ul className="info">
              <li className="f16">
                Total: ${data.game_cnt * 7}, Draw:&nbsp;
                {new Date(data.auDrawTime).toLocaleString('en-AU')}
              </li>

              <li className="f16">
                Purchase:&nbsp;
                {new Date(data.created_at).toLocaleString('en-AU')}
              </li>
            </ul>
          </div>
          <div
            className="btn txt toggle"
            onClick={() => setOpen(prev => !prev)}
          >
            Hide draw details
            <span className="material-symbols-outlined f16">expand_less</span>
          </div>
        </div>
      )}
    </div>
  )
}

import { useState, useEffect } from 'react'
import axios from 'axios'

// component
import Wrapper from '../components/common/wrapper'
import LastWinningResult from '../components/sub/lastWinningResult'
import CountDown from '../components/common/countdown'
import How from './howToPlay'
import Login from '../components/common/login'
import Stat from '../components/game/stat'
import ChooseNumbers from '../components/game/chooseNumbers'
import QuickPlay from '../components/game/quickPlay'
import ErrorPage from './errorPage'

// img
import BankImg from '../img/sub/bank-logo.svg'
import AccountIco from '../img/sub/account-ico.svg'
import MegaLogo from '../img/common/megamillions-logo.png'
import PowerLogo from '../img/common/powerball-logo.png'
import ShineBg from '../img/sub/shine-bg.png'

// tools
import User from '../tools/userInfo'
import { reset, update } from '../tools/zustandUpdate'

interface GameProps {
  type: string
}

interface GameInfo {
  drawnum: number
  jackpot: number
  playdate: string
  orderTime: string
  drawdate: number
}

const Game = ({ type }: GameProps) => {
  const { api, token, info, setTotalInfo } = User()
  const [menu, setMenu] = useState('quick')
  const [gameInfo, setGameInfo] = useState<GameInfo>({
    drawnum: 0,
    jackpot: 0,
    playdate: '',
    orderTime: '',
    drawdate: 0,
  })
  const [open, setOpen] = useState(false)

  const purchaseInfoAxios = (type: string) => {
    const game = type === 'mega' ? 'mm' : 'pb'

    axios
      .get(`${api}/purchase/${game}`, {
        headers: {
          'ngrok-skip-browser-warning': '69420',
        },
      })
      .then(({ data }) => {
        const Data = data.data[0]

        if (data.message === 'success') {
          setGameInfo({
            drawnum: Data.drawnum,
            jackpot: Number(Data.exchange) * Data.jackpot,
            playdate: Data.playdate,
            orderTime: Data.auOrderTime,
            drawdate: Data.isDrawdate,
          })
        }
      })
  }

  useEffect(() => {
    const search = location.search
    const split = search.replace('?', '').split('&')
    const tab = split[0].split('=')[1]

    purchaseInfoAxios(location.pathname.replace('/', ''))

    if (tab) {
      setMenu(tab)
    }

    const empty = {
      address: '',
      birth: '',
      cash: 0,
      city: '',
      country: '',
      createdAt: '',
      email: '',
      firstName: '',
      gender: '',
      lastName: '',
      level: 0,
      marketing: '',
      notify: '',
      number: '',
      phone: '',
      unit: '',
      provider: '',
      prize: 0,
      totalPrize: 0,
    }

    if (token) {
      const payload = JSON.parse(atob(token.split('.')[1]))
      const origin = payload.iss

      update(api, token, setTotalInfo)

      if (!origin.includes('/login') && !origin.includes('/refresh')) {
        reset(setTotalInfo)
      }
    } else {
      if (JSON.stringify(empty) !== JSON.stringify(info)) {
        reset(setTotalInfo)
      }
    }
  }, [])

  const amountMaker = (amount: number) => {
    const inmil = Number((amount / 1000000).toFixed(1))
    let final = ''

    if (inmil > 1000) {
      const inbil = inmil / 1000
      final = `$ ${Math.floor(inbil * 100) / 100} Billions`
    } else if (inmil > 0) {
      final = `$ ${String(inmil)} Millions`
    }

    return final
  }

  return (
    <Wrapper>
      <div className="sub game">
        <div
          className="top-info-area inner"
          style={{ background: type === 'mega' ? '#0361F4' : '#E51502' }}
        >
          <img
            src={type === 'mega' ? MegaLogo : PowerLogo}
            alt="Game Icon"
            className="game-logo"
          />
          <img src={ShineBg} alt="" className="shine-bg" />
          <div className="txt-wrap">
            <p className="f24">
              {type === 'mega' ? 'Mega Millions' : 'Powerball'}
            </p>
            <p className="f40">
              {gameInfo.drawdate
                ? 'To Be Determined'
                : amountMaker(gameInfo.jackpot)}
            </p>
          </div>

          {gameInfo.orderTime && <CountDown date={gameInfo.orderTime} />}
        </div>

        <div className="tab-area">
          <div className="inner">
            <ul className="style01">
              <li
                className={menu === 'quick' ? 'current' : ''}
                onClick={() => (location.href = `/${type}?tab=quick`)}
              >
                Quick Play
              </li>
              <li
                className={menu === 'choose' ? 'current' : ''}
                onClick={() => (location.href = `/${type}?tab=choose`)}
              >
                Choose Numbers
              </li>
            </ul>
            <div className="btn-wrap">
              <div
                className="btn style02"
                onClick={() => (location.href = `/${type}?tab=how`)}
              >
                <span className="material-symbols-outlined f16">
                  question_mark
                </span>
                How to Play
              </div>
            </div>
          </div>
        </div>

        <div className="inner">
          {menu === 'quick' ? (
            <QuickPlay type={type} setLogin={setOpen} gameInfo={gameInfo} />
          ) : menu === 'choose' ? (
            <ChooseNumbers type={type} setLogin={setOpen} gameInfo={gameInfo} />
          ) : menu === 'how' ? (
            <How type={type} />
          ) : menu === 'stat' ? (
            <Stat type={type} />
          ) : (
            <ErrorPage />
          )}
        </div>

        <LastWinningResult
          type={location.pathname.slice(1, location.pathname.length)}
        />
      </div>

      <Login open={open} setOpen={setOpen} />
    </Wrapper>
  )
}

export default Game

import { Dispatch, SetStateAction, useState } from 'react'
import axios from 'axios'
import { CustModal } from './commonElements'
import IconButton from '@mui/material/IconButton'
import { TextField } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useGoogleLogin } from '@react-oauth/google'

// tools
import User from '../../tools/userInfo'
import Swal from 'sweetalert2'
import { update } from '../../tools/zustandUpdate'

// img
import Apple from '../../img/common/apple-ico.svg'
import Google from '../../img/common/google-ico.svg'
import Facebook from '../../img/common/facebook-ico.svg'

interface LoginProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const Login = ({ open, setOpen }: LoginProps) => {
  const { api, setToken, setTotalInfo } = User()

  const [showPassword, setShowPassword] = useState(false)
  const [remember, setRemember] = useState(false)
  const handleClickShowPassword = () => setShowPassword(show => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const googleLogin = useGoogleLogin({
    scope: 'email profile',
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${location.origin}/auth/google`,
    state: location.href,
  })

  const loginAxios = () => {
    const idDom = document.getElementById('idInput') as HTMLInputElement
    const pwDom = document.getElementById(
      'outlined-adornment-password'
    ) as HTMLInputElement

    const idValue = idDom.value
    const pwValue = pwDom.value

    if (remember) {
      localStorage.setItem('id', idValue)
    }

    axios
      .post(
        `${api}/login`,
        { email: idValue, password: pwValue },
        { headers: { 'ngrok-skip-browser-warning': '69420' } }
      )
      .then(({ data }) => {
        if (data.message === 'success') {
          setToken(data.data.token)

          Swal.fire({
            title: 'Login Success',
            icon: 'success',
            allowOutsideClick: false,
            didClose: () => {
              setOpen(false)
              update(api, data.data.token, setTotalInfo)

              if (location.pathname === '/welcome') location.replace('/')
            },
          })
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.message,
          icon: 'error',
          allowOutsideClick: false,
        })
      })
  }

  return (
    <CustModal open={open} setOpen={setOpen} onClose={handleClose}>
      <div className="cont login">
        <div className="login">
          <h3 className="f40">Log In</h3>
          <span
            className="btn close material-symbols-outlined"
            onClick={handleClose}
          >
            close
          </span>
          <div className="cont-area">
            <FormControl sx={{ width: 1, mb: 1 }}>
              <TextField
                id="idInput"
                label="Email"
                type="Email"
                defaultValue={
                  localStorage.getItem('id') ? localStorage.getItem('id') : ''
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    loginAxios()
                  }
                }}
              />
            </FormControl>
            <FormControl sx={{ width: 1, mb: 1 }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    loginAxios()
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <span className="material-symbols-outlined">
                          visibility
                        </span>
                      ) : (
                        <span className="material-symbols-outlined">
                          visibility_off
                        </span>
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            {/* <p className="f16 btn find-pw">Forgot Password?</p> */}
            <a
              href="#"
              style={{ width: '100%' }}
              className="btn style01"
              onClick={() => loginAxios()}
            >
              Log In
            </a>
            <FormControlLabel
              control={
                <Checkbox
                  checked={remember}
                  onChange={({ target }) => setRemember(target.checked)}
                />
              }
              label="Remember Me"
            />

            <div className="line">
              <p>Or</p>
            </div>

            <div className="sns-area">
              {/* <img src={Facebook} alt="Facebook Login" className="btn" /> */}
              <img
                src={Google}
                alt="Google Login"
                className="btn"
                onClick={() => googleLogin()}
              />
              {/* <img src={Apple} alt="Apple Login" className="btn" /> */}
            </div>
            <div className="signup-area">
              <p>Don't have an account?</p>
              <a href="/signup" className="btn f18">
                Sign Up!
              </a>
            </div>
          </div>
        </div>
      </div>
    </CustModal>
  )
}

export default Login

import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import Checkbox from '@mui/material/Checkbox'
import Swal from 'sweetalert2'
import axios from 'axios'

// components
import { CustModal } from '../../components/common/commonElements'

// tools
import { NumberGenerator } from '../../tools/numberGenerator'
import { RandomNumber } from '../../tools/random'
import User from '../../tools/userInfo'
import NoData from '../common/noData'
import { update } from '../../tools/zustandUpdate'

// img
import FavIco from '../../img/sub/favorite-ico.svg'
import FavIcoChk from '../../img/sub/favorite-ico-chk.svg'

interface Value {
  white: number[]
  special: number
}

interface GameInfo {
  drawnum: number
  jackpot: number
  playdate: string
  orderTime: string
}

interface ChooseProps {
  type: string
  setLogin: Dispatch<SetStateAction<boolean>>
  gameInfo: GameInfo
}

interface BallSet {
  [key: string]: Ball
  mega: Ball
  power: Ball
}

interface Ball {
  white: number
  special: number
}

interface LineItem {
  item: Nums
  index: number
  type: string
  focused: number
  game: Nums[]
  setFocused: Dispatch<SetStateAction<number>>
  setFocusedValue: Dispatch<SetStateAction<Value>>
  setGame: Dispatch<SetStateAction<Nums[]>>
  favorite: Favorite[]
  favoriteAxios: () => void
  setLogin: Dispatch<SetStateAction<boolean>>
}

interface Favorite {
  created_at: string
  idx: number
  type: string
  number: number[]
  check: boolean
}

interface Nums {
  num: number[]
  type: string
}

const BallSet: BallSet = {
  mega: {
    white: 70,
    special: 25,
  },
  power: {
    white: 69,
    special: 26,
  },
}

const ChooseNumbers = ({ type, setLogin, gameInfo }: ChooseProps) => {
  const { info, api, token, setTotalInfo } = User()
  const [game, setGame] = useState<Nums[]>([
    { num: [0, 0, 0, 0, 0, 0], type: '' },
  ])
  const [focused, setFocused] = useState<number>(1)
  const [focusedValue, setFocusedValue] = useState<Value>({
    white: [0, 0, 0, 0, 0],
    special: 0,
  })
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [barTop, setBarTop] = useState<number | null>(null)
  const [favorite, setFavorite] = useState<Favorite[]>([])

  const numberSort = (prev: number, next: number) => {
    return prev - next
  }

  console.log(game)

  const makeChoice = (type: string, value: number, index: number) => {
    const copy = [...game]
    const target = { ...focusedValue }
    const white = target.white
    let special = target.special

    if (type === 'special') {
      if (special === 0) {
        special = value
      } else if (special === value) {
        special = 0
      }
    } else {
      if (white.includes(value)) {
        const position = white.findIndex(target => target === value)
        white.splice(position, 1)
        white.push(0)
      } else {
        const position = white.findIndex(target => target === 0)
        white[position] = value
      }
    }

    setFocusedValue({
      white,
      special,
    })

    const sortedArray = white.includes(0)
      ? [...white]
      : [...white].sort(numberSort)

    const newSet = [...sortedArray, special]

    if (copy[index].num.includes(value)) {
      copy[index] = { num: newSet, type: 'M' }
    } else {
      copy[index] = { ...copy[index], num: newSet }
    }

    setGame([...copy])

    setTimeout(() => {
      if (
        newSet.findIndex(target => target === 0) === -1 &&
        copy.length < 100
      ) {
        if (copy[index + 1] === undefined) {
          setGame(prev => [...prev, { num: [0, 0, 0, 0, 0, 0], type: 'M' }])
          setFocusedValue({
            white: [0, 0, 0, 0, 0],
            special: 0,
          })
        } else {
          const target = [...game][index + 1]
          const white = target.num.slice(0, 5)

          setFocusedValue({
            white,
            special: target.num[target.num.length - 1],
          })
        }
        setFocused(prev => prev + 1)
      }
    }, 200)
  }

  const chooseQuick = () => {
    const set = BallSet[type]
    const copy = [...game]
    const target = { ...focusedValue }
    let whiteArray = target.white.filter(target => target !== 0)
    const special = target.special
    let newWhite: number[] = [0, 0, 0, 0, 0]
    let newSpecial = 0

    if (whiteArray.length === 5 && special) {
      setFocusedValue({ white: [0, 0, 0, 0, 0], special: 0 })
      whiteArray = []
      newWhite = NumberGenerator(set.white, [])
      newSpecial = RandomNumber(set.special)
    } else {
      newWhite = NumberGenerator(set.white, whiteArray)
      newSpecial = special ? special : RandomNumber(set.special)
    }

    let i = whiteArray.length
    let copied = [...focusedValue.white]

    let interval = setInterval(() => {
      if (i < 5) {
        copied[i] = newWhite[i]

        setFocusedValue(prev => ({ ...prev, white: copied }))

        i++
      } else {
        setFocusedValue(prev => ({ ...prev, special: newSpecial }))
      }
    }, 200)

    setTimeout(() => {
      clearInterval(interval)

      const newSet = [...newWhite, newSpecial]

      copy[focused - 1] = { num: newSet, type: 'A' }

      setGame(copy)

      if (game[focused] === undefined) {
        if (copy.length < 100) {
          setGame(prev => [...prev, { num: [0, 0, 0, 0, 0, 0], type: 'M' }])
          setFocusedValue({
            white: [0, 0, 0, 0, 0],
            special: 0,
          })
          setFocused(prev => prev + 1)
        }
      } else {
        setFocusedValue({
          white: [...newWhite],
          special: newSpecial,
        })
      }

      setTimeout(() => setLoading(false), 50)
    }, (7 - i) * 200)
  }

  const CapitalTextSelector = () => {
    switch (type) {
      case 'mega':
        return 'Mega'
        break

      case 'power':
        return 'Power'
        break

      default:
        return ''
    }
  }

  const gameCount = game.filter(
    x => JSON.stringify(x.num) !== JSON.stringify([0, 0, 0, 0, 0, 0])
  )

  const checkout = () => {
    if (token) {
      const games = gameCount.map(el => {
        if (
          favorite.some(
            item => JSON.stringify(item.number) === JSON.stringify(el.num)
          )
        ) {
          return { ...el, type: 'F' }
        } else {
          return el
        }
      })

      axios
        .post(
          `${api}/order`,
          {
            gameType: type === 'mega' ? 'MM' : 'PB',
            drawnum: gameInfo.drawnum,
            playdate: gameInfo.playdate,
            gameCnt: games.length,
            nums: games,
            cash: 7 * games.length,
          },
          {
            headers: {
              'ngrok-skip-browser-warning': '69420',
              Authorization: 'bearer ' + token,
            },
          }
        )
        .then(({ data }) => {
          if (data.message === 'success') {
            update(api, token, setTotalInfo)

            Swal.fire({
              title: 'Success',
              text: 'Purchase has been successfully completed',
              icon: 'success',
              allowOutsideClick: false,
              didClose: () => location.reload(),
            })
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: 'Error',
            text: response.data.message,
            icon: 'error',
            allowOutsideClick: false,
          })
        })
    } else {
      setLogin(true)
      sessionStorage.setItem('ball', JSON.stringify(game))
    }
  }

  useEffect(() => {
    const activeList = document.querySelector('.list.active') as HTMLElement
    if (activeList) {
      setBarTop(activeList.offsetTop)
    }
  }, [focused])

  useEffect(() => {
    const ball = sessionStorage.getItem('ball')
    if (ball) {
      const parse = JSON.parse(ball)

      setGame(parse)

      setTimeout(() => sessionStorage.clear(), 50)
    }
  }, [])

  const favoriteAxios = () => {
    const game = type === 'power' ? 'pb' : type === 'mega' ? 'mm' : ''

    if (token) {
      axios
        .get(`${api}/favorite/number/${game}`, {
          headers: {
            'ngrok-skip-browser-warning': '69420',
            Authorization: 'bearer ' + token,
          },
        })
        .then(({ data }) => {
          const empty = []
          for (let i = 0; i < data.data.length; i++) {
            let item = data.data[i]
            let strNumber = item.number
            const numberArray = strNumber
              .split(',')
              .map((str: string) => parseInt(str))
            item.number = numberArray
            item.check = false

            empty.push(item)
          }
          setFavorite(empty)
        })
    }
  }

  useEffect(() => {
    favoriteAxios()
  }, [token])

  const deleteAxios = (id: number) => {
    axios
      .delete(`${api}/favorite/number`, {
        data: {
          ids: [id],
        },
        headers: {
          'ngrok-skip-browser-warning': '69420',
          Authorization: 'bearer ' + token,
        },
      })
      .then(({ data }) => {
        if (data.message === 'success') {
          favoriteAxios()
        }
      })
      .catch(({ response }) => console.log(response.data))
  }

  const checkBoxPut = (index: number) => {
    const copy = [...favorite]

    const target = copy[index]

    target.check = !target.check

    copy[index] = target

    setFavorite(copy)
  }

  const checkBoxAll = () => {
    const copy = [...favorite]

    const allTrue = copy.filter(el => el.check).length === copy.length

    copy.map(el => (el.check = !allTrue))

    setFavorite(copy)
  }

  const addNumbers = () => {
    const checked = [...favorite].filter(el => el.check)

    if (game.length + checked.length < 101) {
      const copyGame = [...game]
      const prior =
        copyGame.length === 1 ? [] : copyGame.slice(0, copyGame.length - 1)

      for (let i = 0; i < checked.length; i++) {
        const index = checked[i]

        prior.push({ num: index.number, type: 'F' })
      }

      const last = copyGame[copyGame.length - 1]

      prior.push(last)

      setGame(prior)

      setOpen(false)

      setFocused(prior.length)

      setFocusedValue({
        white: last.num.slice(0, 5),
        special: last.num[last.num.length - 1],
      })

      const copy = [...favorite]

      copy.map(el => (el.check = false))

      setFavorite([...copy])
    } else {
      Swal.fire({
        title: 'Error',
        text: 'You cannot exceed 100 games per purchase.',
        icon: 'error',
        allowOutsideClick: false,
      })
    }
  }

  return (
    <div className="choose-numbers">
      <div className="step select-area">
        <div className="tit-area flex">
          <p className="f18">
            Line {focused < 10 ? `0${focused}` : `${focused}`}
          </p>
          <div
            className="btn style01 sm"
            onClick={() => {
              if (!loading) {
                setLoading(true)
                chooseQuick()
              }
            }}
          >
            <span className="material-symbols-outlined">bolt</span>
            Quick Pick
          </div>
        </div>
        <div className="cont">
          <div
            className={
              focusedValue.white.includes(0)
                ? 'ball-pick'
                : 'ball-pick completed'
            }
            style={{ marginBottom: '2rem' }}
          >
            <div className="count-picks flex">
              <p>Numbers</p>
              <p>
                <span>
                  {focusedValue.white.filter(target => target > 0).length}
                </span>{' '}
                of 5
              </p>
            </div>
            <div className="balls">
              {new Array(BallSet[type].white).fill(1).map((_, index) => (
                <div
                  key={`white_${index + 1}`}
                  className={
                    focusedValue.white.includes(index + 1)
                      ? `pick ball btn`
                      : `ball btn`
                  }
                  onClick={() => makeChoice('white', index + 1, focused - 1)}
                >
                  {index + 1}
                </div>
              ))}
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((a, i) => {
                return <div className="ball-placeholder" key={i}></div>
              })}
            </div>
          </div>

          <div
            className={`ball-pick ${type} ${
              focusedValue.special > 0 ? 'completed' : ''
            }`}
          >
            <div className="count-picks flex">
              <p>{CapitalTextSelector()} Ball</p>
              <p>
                <span>{focusedValue.special ? 1 : 0}</span> of 1
              </p>
            </div>

            <div className="balls">
              {new Array(BallSet[type].special).fill(1).map((_, index) => (
                <div
                  key={`${type}_ball_${index + 1}`}
                  className={
                    focusedValue.special === index + 1
                      ? `pick ball btn`
                      : `ball btn`
                  }
                  onClick={() => makeChoice('special', index + 1, focused - 1)}
                >
                  {index + 1}
                </div>
              ))}
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((a, i) => {
                return <div className="ball-placeholder" key={i}></div>
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="step selected-area">
        <div className="tit-area flex">
          <p className="f18">Selected Lines</p>
          <div
            className="btn style01 outline sm favorite"
            onClick={() => {
              if (token) {
                setOpen(true)
              } else {
                setLogin(true)
              }
            }}
          >
            <span className="material-symbols-outlined f16">favorite</span>
            Favorites
          </div>

          <CustModal
            open={open}
            setOpen={setOpen}
            onClose={() => setOpen(false)}
          >
            <div className="favorite">
              <div className="tit-area flex">
                <h3 className="f24">Favorites</h3>
                <span
                  className="btn close material-symbols-outlined"
                  onClick={() => setOpen(false)}
                >
                  close
                </span>
              </div>
              <div className="cont">
                <table className="style01">
                  <tr>
                    <th>
                      <Checkbox
                        checked={
                          favorite.filter(el => el.check).length ===
                          favorite.length
                        }
                        onChange={() => checkBoxAll()}
                      />
                    </th>
                    <th>No.</th>
                    <th>Favorites</th>
                    <th>Delete</th>
                  </tr>

                  {favorite.length ? (
                    favorite.map((item, index) => (
                      <tr key={item.idx}>
                        <td>
                          <Checkbox
                            checked={item.check}
                            onChange={() => checkBoxPut(index)}
                          />
                        </td>
                        <td>{index + 1}</td>
                        <td>
                          <div className="balls md">
                            {item.number.map((num, i) =>
                              i === 5 ? (
                                <div className={`ball ${type}`} key={num}>
                                  {num}
                                </div>
                              ) : (
                                <div className="ball" key={num}>
                                  {num}
                                </div>
                              )
                            )}
                          </div>
                        </td>
                        <td>
                          <span
                            className="material-symbols-outlined btn"
                            onClick={() => deleteAxios(item.idx)}
                          >
                            delete
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <NoData />
                      </td>
                    </tr>
                  )}
                </table>
              </div>
              <div className="bottom-area flex">
                <div className="btn-wrap">
                  <div
                    className="btn style01 outline"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </div>
                  <div className="btn style01 em" onClick={() => addNumbers()}>
                    Add
                  </div>
                </div>
              </div>
            </div>
          </CustModal>
        </div>
        <div className="cont">
          <div className="lists">
            <div
              className="bar"
              style={{ top: barTop !== null ? `${barTop}px` : '0px' }}
            />
            {game.map((item, index) => (
              <Line
                key={`Game${index + 1}`}
                item={item}
                index={index}
                type={type}
                focused={focused}
                game={game}
                setFocused={setFocused}
                setFocusedValue={setFocusedValue}
                setGame={setGame}
                favorite={favorite}
                favoriteAxios={favoriteAxios}
                setLogin={setLogin}
              />
            ))}
          </div>

          <div className="pay-info">
            <div className="balance-area flex">
              <p>Available Balance</p>
              <p className="f24">$ {info.cash.toLocaleString()}</p>
            </div>
            <div className="line-area flex">
              <p className="f24 lines">{gameCount.length} Lines</p>
              <div className="total flex">
                <p className="f18">Total : </p>
                <p className="f40">$ {gameCount.length * 7}</p>
              </div>
            </div>
            <div className="btn style01" onClick={() => checkout()}>
              Checkout
              <span className="material-symbols-outlined">chevron_right</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChooseNumbers

const Line = ({
  item,
  index,
  type,
  focused,
  game,
  setFocused,
  setFocusedValue,
  setGame,
  favorite,
  favoriteAxios,
  setLogin,
}: LineItem) => {
  const { api, token } = User()
  const [fav, setFav] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    const nums = favorite.map(el => el.number)
    if (
      nums.findIndex(x => JSON.stringify(x) === JSON.stringify(item.num)) > -1
    ) {
      setFav(true)
    } else {
      setFav(false)
    }
  }, [item, favorite])

  const handleClick = () => {
    if (token) {
      if (!fav) {
        addAxios()
      } else {
        deleteAxios()
      }
    } else {
      setLogin(true)
      sessionStorage.setItem('ball', JSON.stringify(game))
    }
  }

  const handleListClick = (index: number) => {
    const target = [...game][index - 1]
    const white = target.num.slice(0, 5)

    setFocused(index)

    setFocusedValue({
      white,
      special: target.num[target.num.length - 1],
    })
  }

  const deleteSet = (index: number) => {
    const copy = [...game]
    let copyFocused = focused - 1

    if (index + 1 !== copy.length) {
      copy.splice(index, 1)

      setGame(copy)

      if (index < focused) {
        if (focused > 1) {
          setFocused(prev => prev - 1)
        }

        if (copyFocused > 0) {
          copyFocused = copyFocused - 1
        }
      }

      const newCopy = copy[copyFocused].num

      setFocusedValue({
        white: [newCopy[0], newCopy[1], newCopy[2], newCopy[3], newCopy[4]],
        special: newCopy[5],
      })
    }
  }

  const addAxios = () => {
    const game = type === 'power' ? 'PB' : 'MM'

    if (item.num.includes(0)) {
      Swal.fire({
        title: 'Error',
        text: 'You should fill all the numbers to save favorite',
        icon: 'error',
        allowOutsideClick: false,
      })
    } else {
      axios
        .post(
          `${api}/favorite/number`,
          {
            type: game,
            nums: [item.num],
          },
          {
            headers: {
              'ngrok-skip-browser-warning': '69420',
              Authorization: 'bearer ' + token,
            },
          }
        )
        .then(({ data }) => {
          if (data.message === 'success') {
            favoriteAxios()
            setMessage('Favorite Saved')

            setTimeout(() => {
              setMessage('')
            }, 2000)
          }
        })
        .catch(({ response }) => console.log(response.data))
    }
  }

  const deleteAxios = () => {
    const id = favorite.find(
      el => JSON.stringify(el.number) === JSON.stringify(item.num)
    )?.idx

    axios
      .delete(`${api}/favorite/number`, {
        data: {
          ids: [id],
        },
        headers: {
          'ngrok-skip-browser-warning': '69420',
          Authorization: 'bearer ' + token,
        },
      })
      .then(({ data }) => {
        if (data.message === 'success') {
          favoriteAxios()

          setMessage('Favorite Canceled')

          setTimeout(() => {
            setMessage('')
          }, 2000)
        }
      })
      .catch(({ response }) => console.log(response.data))
  }

  return (
    <>
      <div
        className={focused === index + 1 ? 'list active' : 'list'}
        onClick={() => handleListClick(index + 1)}
      >
        <p className="f18">
          {index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}
        </p>

        <div className="balls">
          {item.num.map((num, i) => (
            <div
              className={i + 1 === item.num.length ? `ball ${type}` : 'ball'}
              key={`Game${index}_${num}_${i}`}
            >
              {num ? num : ''}
            </div>
          ))}
        </div>
        <div className="btn-wrap">
          <div
            onClick={() => {
              handleClick()
            }}
            className={`btn favorite ${fav ? 'chk' : ''}`}
          >
            {fav ? (
              <img src={FavIcoChk} alt="favorite Saved" />
            ) : (
              <img src={FavIco} alt="favorite" />
            )}
          </div>

          <div
            className="btn material-symbols-outlined"
            onClick={e => {
              e.stopPropagation()
              deleteSet(index)
            }}
          >
            delete
          </div>
        </div>
      </div>

      {message && (
        <p className="snackbar">
          <span className="material-symbols-outlined">
            {message.includes('Saved') ? 'done' : ''}
          </span>
          {message}
        </p>
      )}
    </>
  )
}

import Countdown from 'react-countdown'

interface ClockProps {
  date: string
}

interface CountDownProps {
  days: number
  hours: number
  minutes: number
  seconds: number
  completed: boolean
}

const CountDown = ({ date }: ClockProps) => {
  return <Countdown date={date} renderer={CountDownView} />
}

export default CountDown

const CountDownView = ({ days, hours, minutes, seconds }: CountDownProps) => {
  const twoDigitConverter = (input: number) => {
    if (input < 10) {
      return `0${input}`
    } else {
      return `${input}`
    }
  }

  const TimeShow = () => {
    return `${twoDigitConverter(days)}:${twoDigitConverter(
      hours
    )}:${twoDigitConverter(minutes)}:${twoDigitConverter(seconds)}`
  }

  return (
    <div className="countdown flex">
      <span className="material-symbols-outlined">alarm_on</span> {TimeShow()}
    </div>
  )
}

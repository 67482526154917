import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
// components
import Main from './pages/main'
import SignUp from './pages/signUp'
import Mega from './pages/mega'
import Power from './pages/power'
import Faq from './pages/faq'
import EmailUs from './pages/emailUs'
import Mypage from './pages/mypage'
import Result from './pages/result'
import Header from './components/common/header'
import Footer from './components/common/footer'
import Privacy from './pages/privacy'
import Terms from './pages/terms'
import AboutUs from './pages/aboutUs'
import News from './pages/news'
import Stat from './pages/stat'
import ErrorPage from './pages/errorPage'
import Google from './pages/google'
import Ticket from './pages/ticket'

function App() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  useEffect(() => {
    if (isMobile && location.href.includes('www')) {
      const m = location.href.replace('www', 'm')
      location.replace(m)
    }
  }, [location])

  return (
    <GoogleOAuthProvider clientId="1710970034-r4l1dgphpsorfqh01upn8ogjmglf143f.apps.googleusercontent.com">
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/welcome" element={<SignUp />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/mega" element={<Mega />} />
        <Route path="/power" element={<Power />} />
        <Route path="/result" element={<Result />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/stat" element={<Stat />} />
        <Route path="/news" element={<News />} />
        <Route path="/email" element={<EmailUs />} />
        <Route path="/mypage" element={<Mypage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/auth/google" element={<Google />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </GoogleOAuthProvider>
  )
}

export default App

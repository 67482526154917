import axios from 'axios'
import { useEffect, useState } from 'react'
import Skeleton from '@mui/material/Skeleton'

// tools
import User from '../../tools/userInfo'

interface SectionType {
  type: string
  original: string
  loading: boolean
}

interface Result {
  date: string
  numbers: string
  draw: number
}

const BallSection = ({ type, original, loading }: SectionType) => {
  const { api } = User()
  const [open, setOpen] = useState(false)
  const [main, setMain] = useState<Result>({
    date: '',
    numbers: '',
    draw: 0,
  })
  const [sub, setSub] = useState<Result[]>([])

  useEffect(() => {
    axios
      .get(`${api}/result/${original.toLowerCase()}`, {
        headers: {
          'ngrok-skip-browser-warning': '69420',
        },
      })
      .then(({ data }) => {
        if (data.message === 'success') {
          const Data = data.data.data

          setMain({
            date: Data[0].auDrawTime.split(' ')[0],
            numbers: Data[0].winningNumber,
            draw: Data[0].drawnum,
          })

          const copy = []

          for (let i = 1; i < 5; i++) {
            const numbers = Data[i].winningNumber
            const date = Data[i].auDrawTime.split(' ')[0]
            const draw = Data[i].drawnum

            copy.push({ date, numbers, draw })
          }

          setSub(copy)
        }
      })
      .catch(response => {})
  }, [])

  return (
    <div className="past">
      <p className="f24">LATEST RESULTS</p>
      <p className="f16 date">
        {loading ? (
          <Skeleton variant="rounded" animation="wave" />
        ) : (
          `${new Date(main.date).toDateString()} (Draw #${main.draw})`
        )}
      </p>

      {loading ? (
        <Skeleton variant="rounded" animation="wave" width={300} height={60} />
      ) : (
        <div className="balls">
          {main.numbers.split(',').map((num, index) => (
            <div
              className={`ball ${
                index === main.numbers.split(',').length - 1 ? type : ''
              }`}
              key={`${main.date}_${num}`}
            >
              {num}
            </div>
          ))}

          <div className="expand-cont-wrap">
            <div
              className={`btn style03 expand ${open ? 'active' : ''}`}
              onClick={() => {
                setOpen(prev => !prev)
              }}
            >
              <span className="material-symbols-outlined">expand_more</span>
            </div>

            {open && (
              <div className="cont">
                <ul className="win-num-lists">
                  {sub.map(item => (
                    <li className="flex" key={item.date}>
                      {/* <p className="f14">{item.date.replaceAll("-", ".")}</p> */}
                      <p className="f14">
                        {new Date(item.date).toDateString()} (Draw #{item.draw})
                      </p>
                      <div className="balls">
                        {item.numbers.split(',').map((num, index) => (
                          <div
                            className={`ball ${
                              index === main.numbers.split(',').length - 1
                                ? type
                                : ''
                            }`}
                            key={`${main.date}_${num}`}
                          >
                            {num}
                          </div>
                        ))}
                      </div>
                    </li>
                  ))}
                </ul>

                <div className="btn-wrap flex">
                  {/* <div
                  className="btn style03 expand"
                  onClick={() => setOpen(prev => !prev)}
                >
                  <span className="material-symbols-outlined">expand_less</span>
                </div> */}

                  <div
                    className="btn style01 sm"
                    onClick={() => (location.href = `result?tab=${type}`)}
                  >
                    View More
                    <span className="material-symbols-outlined f16">
                      chevron_right
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default BallSection

import { useEffect, useState } from 'react'
import axios from 'axios'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Swal from 'sweetalert2'

// componemts
import Wrapper from '../components/common/wrapper'
import Draws from '../components/mypage/draws'
import History from '../components/mypage/history'
import Settings from '../components/mypage/settings'
import { CustModal } from '../components/common/commonElements'

// tools
import User from '../tools/userInfo'
import { update } from '../tools/zustandUpdate'

// img
import BankImg from '../img/sub/bank-logo.svg'

const Mypage = () => {
  const { api, token, setTotalInfo, info } = User()
  const [menu, setMenu] = useState('')
  const [open, setOpen] = useState(false)
  const [openWithdraw, setOpenWithDraw] = useState(false)
  const [amount, setAmount] = useState(0)
  const [selectedValue, setSelectedValue] = useState('total')

  const changeView = (type: string) => {
    switch (type) {
      case 'draw':
        return <Draws />
        break

      case 'history':
        return <History />
        break

      case 'settings':
        return <Settings />
    }
  }

  if (!token) {
    Swal.fire({
      title: 'Members Only',
      text: 'This page is only for Lottery Cluster members',
      icon: 'error',
      allowOutsideClick: false,
      didClose: () => location.replace('/'),
    })
  }

  useEffect(() => {
    update(api, token, setTotalInfo)

    const { search } = location

    const [menu] = search.replace('?', '').split('&')

    setMenu(menu.split('=')[1] ? menu.split('=')[1] : 'draw')
  }, [])

  const chargeCash = () => {
    if (amount) {
      axios
        .post(
          `${api}/cash/charge/bank`,
          {
            cash: amount,
            price: amount,
            item_name: `$ ${amount}`,
          },
          {
            headers: {
              'ngrok-skip-browser-warning': '69420',
              Authorization: 'bearer ' + token,
            },
          }
        )
        .then(({ data }) => {
          if (data.message) {
            Swal.fire({
              title: 'Success',
              icon: 'success',
              allowOutsideClick: false,
              didClose: () => location.reload(),
            })
          }
        })
        .catch(({ response }) => {
          const status = response.status

          if (status === 409) {
            Swal.fire({
              title: 'Error',
              text: "You have a charge request that hasn't been completed.",
              icon: 'error',
              allowOutsideClick: false,
            })
          }
        })
    } else {
      Swal.fire({
        title: 'Error',
        text: 'You cannot charge $0. Please select other option.',
        icon: 'error',
        allowOutsideClick: false,
      })
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value)
  }

  return (
    <Wrapper>
      <div className="sub mypage">
        <div className="top-info-area inner">
          <ul className="tab">
            <li
              className={menu === 'draw' ? 'current' : ''}
              onClick={() => (location.href = '/mypage?tab=draw')}
            >
              Draws
            </li>
            <li
              className={menu === 'history' ? 'current' : ''}
              onClick={() => (location.href = '/mypage?tab=history')}
            >
              History
            </li>
            <li
              className={menu === 'settings' ? 'current' : ''}
              onClick={() => (location.href = '/mypage?tab=settings')}
            >
              Settings
            </li>
          </ul>

          <div className="account-balance">
            <div className="item">
              <h4 className="f16">Account Balance</h4>
              <p className="f24">$ {info.cash.toLocaleString('au')}</p>
              <div className="btn style01 sm" onClick={() => setOpen(true)}>
                Add Funds
              </div>
            </div>
            <div className="item">
              <h4 className="f16">My Winnings</h4>
              <p className="f24">$ {info.prize}</p>
              <div
                className="btn style01 sm withdraw"
                onClick={() => setOpenWithDraw(true)}
              >
                Withdraw
              </div>
            </div>
          </div>
        </div>

        <div className="inner cont">{changeView(menu)}</div>
      </div>

      <CustModal open={open} setOpen={setOpen} onClose={() => setOpen(false)}>
        <div className="add-funds">
          <div className="tit-area flex">
            <h3 className="f24">Add Funds</h3>
            <span
              className="btn close material-symbols-outlined"
              onClick={() => setOpen(false)}
            >
              close
            </span>
          </div>
          <div className="cont">
            <table className="form">
              <colgroup>
                <col width="20%" />
                <col width="80%" />
              </colgroup>

              <tr>
                <th>Funds</th>
                <td>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Funds</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={amount}
                      label="Funds"
                      onChange={({ target }) => {
                        const value = target.value as number
                        setAmount(value)
                      }}
                    >
                      {new Array(10).fill(1).map((_, index) => (
                        <MenuItem
                          value={7 * (index + 1)}
                          key={`money_${index * 7}_A$`}
                        >
                          $ {7 * (index + 1)}.00
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </td>
              </tr>
            </table>
            <div className="company-account-info">
              <div className="tit-area flex">
                <h3 className="f24">Account Informations</h3>
              </div>
              <div className="cont">
                <div className="info">
                  <img src={BankImg} alt="Line bank" />
                  <ul>
                    <li>
                      <p className="tit">Deposit Amount : </p>
                      <p className="txt f24">
                        $ {amount.toLocaleString('au')}.00
                      </p>
                    </li>
                    <li>
                      <p className="tit">Account Number : </p>
                      <p className="txt f24">1234 5678 9000</p>
                    </li>
                    <li>
                      <p className="tit">Account Holder : </p>
                      <p className="txt">The Lottery Cluster</p>
                    </li>
                  </ul>
                </div>
                <p className="exp">
                  After making a deposit via bank transfer, you can check your
                  transaction details on My Page.
                </p>
              </div>
            </div>
          </div>
          <div className="bottom-area flex">
            <div className="btn-wrap">
              <div
                className="btn style01 outline"
                onClick={() => setOpen(false)}
              >
                Cancel
              </div>
              <div className="btn style01 em" onClick={() => chargeCash()}>
                Add
              </div>
            </div>
          </div>
        </div>
      </CustModal>

      <CustModal
        open={openWithdraw}
        setOpen={setOpenWithDraw}
        onClose={() => setOpenWithDraw(false)}
      >
        <div className="withdraw">
          <div className="tit-area flex">
            <h3 className="f24">Withdraw</h3>
            <span
              className="btn close material-symbols-outlined"
              onClick={() => setOpenWithDraw(false)}
            >
              close
            </span>
          </div>
          <div className="cont">
            <div className="my-account-info">
              <p className="f16">Account Number : 1234 5678 9010</p>
            </div>
            <RadioGroup
              value={selectedValue}
              onChange={handleChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="total"
                control={<Radio />}
                label={
                  <div className="amount">
                    <p className="f14">Total amount</p>
                    <p className="f18">$ 10,000,000</p>
                  </div>
                }
                className={selectedValue === 'total' ? 'item chk' : 'item'}
              />
              <div className="other-wrap">
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label={
                    <div className="amount">
                      <p className="f14">Other amount</p>
                      <TextField
                        disabled={selectedValue !== 'other'}
                        className="other-textfield"
                        size="small"
                        sx={{ width: '150px' }}
                      />
                    </div>
                  }
                  className={selectedValue === 'other' ? 'item chk' : 'item'}
                />
              </div>
            </RadioGroup>
          </div>
          <div className="bottom-area flex">
            <div className="btn-wrap">
              <div
                className="btn style01 outline"
                onClick={() => setOpenWithDraw(false)}
              >
                Cancel
              </div>
              <div
                className="btn style01"
                onClick={() => setOpenWithDraw(false)}
              >
                Withdraw
              </div>
            </div>
          </div>
        </div>
      </CustModal>
    </Wrapper>
  )
}

export default Mypage

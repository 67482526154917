export const Menu = [
  { id: 1, text: "HOME", link: "/" },
  { id: 2, text: "Mega Millions", link: "/mega" },
  { id: 3, text: "Powerball", link: "/power" },
  { id: 4, text: "Results", link: "/result" },
  { id: 5, text: "Statistics", link: "/stat" },
  { id: 6, text: "Contact Us", link: "/email" },
  { id: 7, text: "FAQ", link: "/faq" },
];

export const TopUnLogged = [
  { id: 1, text: "Log In", icon: "login" },
  { id: 2, text: "Sign Up", icon: "person_add" },
];

export const TopLogged = [
  { id: 1, text: "Log Out", icon: "logout" },
  { id: 2, text: "My Page", icon: "account_circle" },
];

export const DrawerMenu = [
  { id: 1, text: "Result", link: "/result" },
  { id: 2, text: "Statistics", link: "/stat" },
  { id: 3, text: "Contact Us", link: "/email" },
  { id: 4, text: "FAQ", link: "/faq" },
  { id: 5, text: "About Us", link: "/about" },
];

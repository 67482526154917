import { Dispatch, SetStateAction, useState } from "react";
import TextField from "@mui/material/TextField";

import Mastercard from "../../img/sub/master-ico.svg";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const PayMethod = ({ setOpen }: ModalContent) => {
  return (
    <div className="pay-method">
      <div className="tit-area flex">
        <h3 className="f24">Manage Payment Methods</h3>
        <span
          className="btn close material-symbols-outlined"
          onClick={() => setOpen(false)}
        >
          close
        </span>
      </div>
      <div className="cont">
        <div className="methods">
          <div className="method">
            <div className="summary">
              <img src={Mastercard} alt="Master Card" />
              <p className="f15">521729xxxxxxx084</p>
              <p className="expired">EXPIRED</p>
            </div>
            <div className="expand">
              <p className="f15">CARD HOLDER</p>
              <p className="f18">KIM GU</p>
              <p className="f18">EXPIRY DATE</p>
              <p className="f18">4/24</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-area flex">
        <div className="btn-wrap">
          <div className="btn style01 outline" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div className="btn style01 em">Set</div>
        </div>
      </div>
    </div>
  );
};

export default PayMethod;

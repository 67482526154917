import sun from '../../img/common/sun.png'
import cloud from '../../img/common/cloud.png'

const MainBg = () => {
  return (
    <div className="main-bg">
      <img src={sun} alt="sun" className="sun" />
      <img src={cloud} alt="cloud" className="cloud" />
    </div>
  )
}

export { MainBg }
